import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {User} from '../../../app/shared/types/user';
import {Stream} from '../../../app/shared/types/stream';
import {Note} from '../../../app/shared/types/note';

import {UserService} from '../../../app/shared/services/user_service';
import {StreamService} from '../../../app/shared/services/stream_service';
import {NoteService} from '../../../app/shared/services/note_service';
import {AdminService} from '../../services/admin.service';
import {CategoriesService} from '../../../app/shared/services/categories_service';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../../app/shared/confirmation-modal/confirmation-modal.component';
import {ValidateNoteName} from '../../../app/shared/validators/note-name.validator';
import {QuillEditorConfiguration} from '../../../app/shared/quill/custom_config';
import {ImageUpload} from '../../../app/shared/quill/image-upload.module';
import {ContentParagraph} from '../../../app/shared/quill/content-paragraph.module';
import {ImageEditor} from '../../../app/shared/quill/image-editor.module';
import {ImageFormat} from '../../../app/shared/quill/content-format.module';

import * as Quill from 'quill';

const maxTitleLen = 80;
const maxLeadLen = 262144; // 256KB.

export interface NoteExtType extends Note {
  flags: any[];
}

@Component({
  selector: 'notd-backoffice-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @ViewChild('streamPayments', {static: false}) streamPayments: ElementRef;
  @ViewChild('flagActionModal', {static: false}) flagActionModal: ElementRef;


  loadingNote = true;
  note: NoteExtType;
  noteState = ['PUBLIC', 'HIDDEN', 'TAKEN_DOWN'];
  noteTags = [];
  noteDebaters = [];
  noteIdDeleted = '';

  quillConfig: any;
  noteForm: UntypedFormGroup;
  loadingContent: boolean;
  categoriesData: any = [];
  categoriesLimit: number[] = [1, 5];
  selectedCategories: any = [];
  tagsLimitValid = true;
  imagePreloader: any = {
    show: false,
    progress: 0
  };
  thumbnailMaxSizeKb = 3146000;
  thumbnailErrors = {
    limit: false,
    other: ''
  };
  fileToUpload: File = null;
  currentNoteThumb: string;
  savingPostProgress = false;

  constructor(
      private router: Router,
      private userService: UserService,
      private streamService: StreamService,
      private noteService: NoteService,
      private adminService: AdminService,
      private categoriesService: CategoriesService,
      private modalService: NgbModal,
      private fb: UntypedFormBuilder,
      private renderer: Renderer2,
      private route: ActivatedRoute) { }

  ngOnInit() {
    const quillEditorConfig = new QuillEditorConfiguration();
    this.quillConfig = quillEditorConfig.getConfig();

    Quill['register'](ImageFormat, true);
    Quill['register']('modules/imageEditor', ImageEditor);
    Quill['register']('modules/contentParagraph', ContentParagraph);
    // Quill['register']('modules/imageDrop', ImageDropModule);
    Quill['register']('modules/imageUpload', ImageUpload);

    Object.assign(this.quillConfig, {
      // imageDrop: true,
      imageEditor: true,
      imageUpload: {
        isNoteIdEmpty: () => (this.note && !this.note.id) || !this.note,
        showErrorMessage: () => {
          this.showErrorMessage(
            'Add picture',
            'Before adding a new image please select your stream.'
          );
          // this.scrollTo('note-title');
        },
        getNoteId: () => this.note.id,
        imageFileUploading: () => {
          console.log('imageFileUploading');
          this.imagePreloader = {
            show: true,
            progress: 0
          };
        },
        imageFileUploadedProgress: (progress: any) => {
          console.log('imageFileUploadedProgress', progress);
          this.imagePreloader.progress = progress;
        },
        imageFileUploaded: () => {
          console.log('imageFileUploaded');
          this.imagePreloader.show = false;
          window.dispatchEvent(new Event('quillImageUploaded'));
        },
        imageFileError: () => {
          console.log('imageFileError');
          this.imagePreloader = {
            show: false,
            progress: 0
          };
          this.showErrorMessage(
            'Add picture',
            'There was an issue while uploading the image. Please try again.'
          );
        },
        noteService: this.noteService
      },
      contentParagraph: true
    });

    this.route.params.subscribe((param) => {
      this.getNoteDetails(param.id);

      this.getCategories();
      this.createForm();
    });
  }

  getNoteDetails(noteId: string) {
    this.loadingNote = true;
    this.noteTags = [];
    this.noteDebaters = [];
    this.noteService.getNoteInfo(noteId, true).then((res: NoteExtType) => {
      this.note = res;
      this.loadingNote = false;

      this.userService.getUserProfile(this.note.userId).then((userDetails: User) => {
        Object.assign(this.note, {
          userName: userDetails.displayName
        });
      });

      this.noteService.getNote(noteId, true).then((note: any) => {
        console.log('getNote', note);

        this.noteForm.patchValue({
          title: note.name,
          content: note.premiumContent,
          leadContent: note.leadContent['changingThisBreaksApplicationSecurity']
        });

        let formType = '';

        note.tags.map((tag: any) => {
          const splitTag: any[] = tag.split('-');

          if (splitTag[0] === 'form' && splitTag[0] !== formType) {
            formType = splitTag[1];
          }

          if (splitTag[0] === 'category' &&
            splitTag.length > 1 &&
            this.categoriesService
              .getCategoryData(
                formType,
                (splitTag[1] && splitTag[2]) ? `${splitTag[1]}-${splitTag[2]}` : splitTag[1]).length > 0) {
            this.collectCategories({
              form: formType,
              category: (splitTag[1] && splitTag[2]) ? `${splitTag[1]}-${splitTag[2]}` : splitTag[1]
            });
          }
        });
      }).catch(err => {
        console.error('getNote', err);
      });

      this.adminService.getNoteFlags(noteId).then(flags => {
        this.note.flags = flags;
      });

      this.setNoteTags();
      this.setDebaters();
      this.getStreamInfo();
    });
  }

  getCategories() {
    this.categoriesData = this.categoriesService.getStructure();
  }

  collectCategories(cat: any) {
    const itemExist = this.selectedCategories.filter(c => c.form === cat.form && c.category === cat.category);

    if (itemExist.length === 0) {
      this.selectedCategories.push(cat);
      this.categoriesData.map((form: any) => {
        if (form.type === cat.form) {
          Object.assign(form, { selected: true });

          form.categories.map((category: any) => {
            if (category.category === cat.category) {
              Object.assign(category, { selected: true });
            }
          });
        }
      });
    } else {
      this.selectedCategories = this.selectedCategories.filter(c => `${c.form}-${c.category}` !== `${cat.form}-${cat.category}`);

      const catInForm = this.selectedCategories.filter(c => c.form === cat.form);

      this.categoriesData.map((form: any) => {
        if (form.type === cat.form && catInForm.length === 0) {
          Object.assign(form, { selected: false });
        }

        form.categories.map((category: any) => {
          if (category.category === cat.category) {
            Object.assign(category, { selected: false });
          }
        });
      });
    }

    this.tagsValidation();
  }

  getTagLabel(tag: any) {
    return `${this.categoriesService.getTypeLabel(tag.form)} - ${this.categoriesService.getCategoryLabel(tag.category)}`;
  }

  tagsValidation() {
    const selCat = this.selectedCategories;
    this.tagsLimitValid = (selCat.length < this.categoriesLimit[0] || selCat.length > this.categoriesLimit[1]) ? false : true;
  }

  generateTags() {
    const tags: string[] = [];

    this.selectedCategories.map((tag: any) => {
      tags.push(`form-${tag['form']}`);
      tags.push(`category-${tag['category']}`);
    });

    return tags;
  }

  setNoteTags() {
    this.note.tags.map(tag => {
      const newTag = tag.includes('form-') ? tag.replace('form-', 'type-') : tag;
      const splitTag: any[] = newTag.split('-');

      if (splitTag[0] === 'type' || splitTag[0] === 'category') {
        tag = (splitTag.length > 2) ? `${splitTag[1].replace('-', '')} ${splitTag[2]}` : splitTag[1].replace('-', '');

        this.noteTags.push(tag);
      }

    });
  }

  setNoteState(state: string) {
    this.noteService.updateNoteState(this.note.id, state).then(res => {
      console.log('updateNoteState: ', res);
      this.getNoteDetails(this.note.id);
    });
  }

  setDebaters() {
    this.note.debaters.map(debater => {
      this.userService.getUserProfile(debater).then((userDetails: User) => {
        this.noteDebaters.push({
          userId: userDetails.id,
          userName: userDetails.displayName
        });
      });
    });
  }

  deleteNote(noteId: string) {
    this.noteIdDeleted = noteId;
    this.showConfirmationModal({
      title: `Delete note confirmation`,
      question: 'Do you really want to remove this note?'
    });
  }

  getStreamInfo() {
    this.streamService.getStreamInfoObservable(this.note.channelId).subscribe((res: Stream) => {
      Object.assign(this.note, {
        streamName: res.name
      });
    });
  }

  showConfirmationModal(modalDetail: {title: string; question: string}) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = modalDetail.title;
    refModalConf.componentInstance.confirmationModalMsg = modalDetail.question;
    refModalConf.componentInstance.buttons = {
      first: 'Delete',
      second: 'Cancel'
    };

    refModalConf.result.then(resp => {
      if (resp) {
        this.noteService.deleteNote(this.noteIdDeleted).subscribe(() => {
          this.router.navigate(['./notes']);
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  createForm() {
    this.noteForm = this.fb.group({
      title: ['',
        [
          Validators.required,
          Validators.maxLength(maxTitleLen),
          ValidateNoteName
        ]
      ],
      content: ['', Validators.required],
      leadContent: ['', Validators.required],
    });
  }

  annotationStatusChange(event: Event) {
    console.log(event);
  }

  removeAnnotationsFromContent(): Promise<boolean> {
    const qlEditor = document.querySelector('.ql-container .ql-editor');
    Array.from(qlEditor.querySelectorAll('.ann-point')).forEach(point => {
      this.renderer.removeChild(qlEditor, point);
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 50);
    });
  }

  showErrorMessage(errorTitle, errorMsg) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.onlyMessage = true;
    refModalConf.componentInstance.confirmationModalTitle = errorTitle;
    refModalConf.componentInstance.confirmationModalMsg = errorMsg;

    refModalConf.result.then(resp => {
      if (resp) {
        console.log(resp);
      }
    })
      .catch(err => {
        console.log(err);
      });
  }

  savedThumbnail(e: any) {
    this.thumbnailErrors.limit = false;
    this.thumbnailErrors.other = '';

    const newImg = e['dataURL'];
    const imgSize = newImg.replace(/=/g, '').length * 0.75;

    if (imgSize > this.thumbnailMaxSizeKb) {
      this.thumbnailErrors.limit = true;
    } else {
      fetch(newImg)
        .then(res => res.blob())
        .then(blob => {
          const fileName = e.name ? e.name : `${new Date().getTime()}.${blob.type.split('/')[1]}`;
          this.fileToUpload = new File([blob], fileName, {type: blob.type});
          this.note.thumbnailImage = newImg;
        });
    }
  }

  saveChanges() {
    this.savingPostProgress = true;
    this.note = Object.assign(this.note, {
      name: this.noteForm.get('title').value,
      indexedName: this.noteService.urlifyName(this.noteForm.get('title').value),
      urlName: this.noteService.urlifyName(this.noteForm.get('title').value),
      premiumContent: document.querySelector('.ql-container .ql-editor').innerHTML,
      leadContent: this.noteForm.get('leadContent').value,
      tags: this.generateTags()
    });

    const promisesArray = [
      this.note.premiumContent ? this.noteService.updateNotePremiumContent(this.note.id, this.note.premiumContent.toString()) : '',
      this.note.leadContent ? this.noteService.updateNoteLeadContent(this.note.id, this.note.leadContent.toString()) : '',
      this.note.name ? this.noteService.updateNoteName(this.note.id, this.note.name) : '',
      this.note.tags.length > 0 ? this.noteService.updateNoteTags(this.note.id, this.note.tags) : '',
    ];

    if (this.fileToUpload) {
      promisesArray.push(this.noteService.uploadThumbnail(this.fileToUpload, this.note.id));
    }

    Promise.all(promisesArray).then(() => {
      // this.cacheService.clearCachedPost(note.id);
      this.router.navigate(['/notes']);
    }, (err) => {
      this.savingPostProgress = false;
      const error = err.error || {};
      if (error && error.code) {
        switch (error.code) {
          case 'INVALID_INPUT_TOO_LONG':
            if (err.url && err.url.endsWith('/name')) {
              this.contentUploadErrorModal(`Note title can be maximum ${maxTitleLen} characters long.`);
            } else if (err.url && err.url.endsWith('/lead/content')) {
              this.contentUploadErrorModal(`Excerpt lead content can be up to ${maxLeadLen} characters long.`);
            } else {
              this.contentUploadErrorModal('The size of content is to big, it has to be less than 10 mb.');
            }
            break;
          default:
            this.contentUploadErrorModal(error.message);
            break;
        }
      } else if (err.status === 413) {
        this.contentUploadErrorModal('There is a lot of content, which may have performance issues. ' +
          'Please try and reduce your image size before adding it to the note');
      } else {
        this.contentUploadErrorModal('There was an error during note saving. Please try again! '
          + ' If error persists, contact support and provide the exact text and image you are trying to create');
      }
    }).catch(() => {
        this.savingPostProgress = false;
        this.contentUploadErrorModal('There was an error during note saving. Please try again!');
      });
  }

  contentUploadErrorModal(errorMsg) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.onlyMessage = true;
    refModalConf.componentInstance.confirmationModalTitle = 'Error saving note';
    refModalConf.componentInstance.confirmationModalMsg = errorMsg;

    refModalConf.result.then(resp => {
      if (resp) {
        console.log(resp);
      }
    });
  }

  public flagAction(flag: any): void {
    const refModalConf = this.modalService.open(this.flagActionModal, {});

    refModalConf.result.then((resp: string) => {
      if (resp) {
        if (resp === 'accept') {
          this.adminService.acceptFlag(flag.id).then(() => {
            setTimeout(() => {
              this.getNoteDetails(this.note.id);
            }, 1000);
          }, err => {
            console.error('Error while accepting flag', err);
          });
        }
        if (resp === 'reject') {
          this.adminService.rejectFlag(flag.id).then(() => {
            setTimeout(() => {
              this.getNoteDetails(this.note.id);
            }, 1000);
          }, err => {
            console.error('Error while rejecting flag', err);
          });
        }
      }
    }).catch(err => {
      console.error(err, 'quit the modal');
    });
  }
}
