<div class="notd-dropdown">
  <button (click)="dropdownToggle()"
          [ngClass]="{'notd-dropdown__button--toggle' : toggleStatus}"
          class="notd-dropdown__button">{{buttonLabel ? buttonLabel : 'Select element from the list'}}</button>
  <ul class="notd-dropdown__list"
      [ngClass]="{'notd-dropdown__list--visible': toggleStatus}">
    <li class="notd-dropdown__list__element"
        *ngFor="let option of options; let i = index">
      <a class="notd-dropdown__list__element__link"
         [ngClass]="{'notd-dropdown__list__element__link--selected': option[objectConvert.selected]}"
         (click)="clickOnListElement(option)">{{option[objectConvert.label]}}</a>
    </li>
  </ul>
</div>
