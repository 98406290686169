import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
import {User} from '../../app/shared/types/user';
import {AdminService} from '../services/admin.service';


@Component({
  selector: 'notd-boffice-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  curUser: User;
  isLoggedIn;
  search: any = {};

  constructor(
      private adminService: AdminService,
      private userService: UserService,
      private router: Router,
      private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
  }

  private closeMenu() {
    if (window.innerWidth <= 991) {
      const tBut: any = document.getElementById('notd-toggler-button');
      if (tBut) {
        tBut.click();
      }
    }
  }
}
