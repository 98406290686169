/* eslint-disable @typescript-eslint/naming-convention */

export enum PayoutDestination {
  // constant members
  Invalid = 0,
  Bank = 1, //
  Check = 2, //
  Transact = 3, // Payment to transact
  Last = 4, //
}

