<footer class="sticky-footer">
  <div class="text-center">
    <small>Copyright © Notd Backoffice 2024</small>
    <small *ngIf="buildDate">
      &nbsp;&nbsp;&nbsp;
      Build: {{buildDate | date: 'longDate'}}
      Ver: {{ verHash }}
    </small>

  </div>
  <div class="text-center">


  </div>
</footer>
