import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../app/shared/services/user_service';
import {AdminService} from '../../services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notd-boffice-flagged-notes',
  templateUrl: './flagged.component.html',
  styleUrls: ['./flagged.component.scss']
})
export class FlaggedComponent implements OnInit {
  notesList = [];
  stateTypes = ['NEW', 'VALID', 'INVALID'];
  loading = false;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.getFlaggedNotes(this.stateTypes[0]);
  }

  public changeNoteState(event: Event) {
    const selectedState = event.target['value'];
    this.getFlaggedNotes(selectedState);
  }

  private getFlaggedNotes(state: string): void {
    this.notesList = [];
    const notesList = [];
    this.loading = true;
    this.adminService.getFlaggedNotes(state).then((result: any) => {
      if (result && result.length > 0) {
        const notesInfoRequests = [];
        const notesFlagsRequests = [];

        for (const r of result) {
          notesInfoRequests.push(this.adminService.getNoteInfo(r.postId));
          notesFlagsRequests.push(this.adminService.getNoteFlags(r.postId));
        }

        Promise.all(notesInfoRequests).then(notesInfo => {
          for (const n of notesInfo) {
            for (const re of result) {
              if (re.postId === n.id) {
                notesList.push(n);
              }
            }
          }

          Promise.all(notesFlagsRequests).then(flags => {
            for (const n of notesList) {
              for (const f of flags) {
                const flag = f[0];

                if (n.id === flag.postId) {
                  n.flags = f;
                }
              }
            }

            this.notesList = notesList;
            this.loading = false;
          }, err => {
            console.error('There was and error while fetching flagged notes flags', err.error || err);
          });
        }, err => {
          console.error('There was and error while fetching flagged notes info', err.error || err);
        });
      } else {
        this.loading = false;
      }
    });
  }

  getNoteFlags(noteId: string) {
    this.adminService.getNoteFlags(noteId).then(res => {
      const note = this.notesList.filter(n => n.id === noteId)[0];
      Object.assign(note, {
        states: res
      });
    });
  }
}
