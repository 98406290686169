/* eslint-disable @typescript-eslint/naming-convention */

export enum PayoutStatus {
  // constant members
  Invalid = 0,
  Requested = 1, //
  Processing = 2, //
  Sent = 3, //
  Rejected = 4, //
}
