export class ImageEditor {
  quill: any;
  options: any;
  img: any;
  imgResizeNodeRectCopy: any;
  rayPos: any;
  defaultCaptionText = 'Image caption goes here...';
  // currentWrappedImages = 0;
  isMobile = false;
  ids = 0;
  captionSize = {
    width: 160,
    height: 30
  };
  minImgWidth = 300;
  selection: any;
  imgFromCaption: any;
  savedRange: any;
  rotationInterval;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    this.quill
      .getModule('toolbar')
      .addHandler('link', this.showLinkContainer.bind(this));

    this.clickOnQuill = this.clickOnQuill.bind(this);
    this.initImageResize = this.initImageResize.bind(this);
    this.startResizing = this.startResizing.bind(this);
    this.stopResizing = this.stopResizing.bind(this);
    this.editImg = this.editImg.bind(this);
    this.getImgNode = this.getImgNode.bind(this);
    this.hideEditor = this.hideEditor.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onCaptionClick = this.onCaptionClick.bind(this);
    this.addLinkContainer = this.addLinkContainer.bind(this);
    this.lookForLinks = this.lookForLinks.bind(this);
    this.onImagesLoad = this.onImagesLoad.bind(this);

    this.quill.root.addEventListener('click', this.clickOnQuill, false);

    this.isMobile = this.quill.container.parentNode.classList.contains('is-mobile');

    this.appendImageEditionPanel();

    this.quill.on('editor-change', (e) => {
      this.init();
    });

    const loadedContentInterval = setInterval(() => {
      if (this.quill.container.parentNode.classList.contains('loaded')) {
        console.log('loaded!');
        this.init();
        clearInterval(loadedContentInterval);
      }
    }, 1000);

    this.addLinkContainer();

    this.quill.container.addEventListener('mouseup', () => {
      if (document.getSelection().type && document.getSelection().type === 'Range' ) {
        this.savedRange = document.getSelection().getRangeAt(0).cloneRange();
      }
    });

    this.lookForImageEmptySpace();

    window.addEventListener('quillImageUploaded', () => {
      setTimeout(() => {
        this.setImgWrappersAndCaptions();
      }, 500);
    }, false);

    document.addEventListener('click', (event: any) => {
      const targetEl = event.target;

      if (
        targetEl &&
        targetEl.classList.contains('ql-link-wrapper-bg') &&
        targetEl.classList.contains('show')) {
        this.hideLinkContainer();
      }
    }, false);
  }

  init() {
    this.onImagesLoad();
    this.setImgWrappersAndCaptions();

    if (this.img) {
      this.setImageEditorPosition(this.img);
    }

    const captions = this.quill.root.parentNode.querySelectorAll('.caption-block');

    if (captions.length) {
      for (const c of captions) {
        c.addEventListener('click', this.onCaptionClick, false);
        c.addEventListener('keyup', this.onCaptionClick, false);
      }
    }
  }

  onImagesLoad() {
    const contentImages = this.quill.container.querySelector('.ql-editor').querySelectorAll('p img');

    if (contentImages.length) {
      for (const i of contentImages) {
        i.addEventListener('load', e => {
          i['dataset']['loaded'] = 'true';
          this.setImgWrappersAndCaptions(true);
          this.setElementsPositionAfterInitLoad(i);
        });
      }
    }
  }

  clickOnQuill(event: Event) {
    this.imgFromCaption = null;
    this.hideEditor();
    this.lookForLinks(event);
  }

  // append image editor panel to quill editor
  appendImageEditionPanel() {
    const rootWrapper = this.quill.root.parentNode;
    const imageEditorPanel = document.createElement('div');

    imageEditorPanel.className = 'image-editor-panel';
    imageEditorPanel.style.padding = '6px';
    imageEditorPanel.style.display = 'none'; // should be none
    imageEditorPanel.style.width = 'auto';
    imageEditorPanel.style.background = '#fff';
    imageEditorPanel.style.position = 'absolute';
    imageEditorPanel.style.left = '0';
    imageEditorPanel.style.zIndex = '2';
    imageEditorPanel.style.borderRadius = '10px';

    const actionButtonsWrapper = document.createElement('div');
    actionButtonsWrapper.className = 'action-buttons-wrapper';

    const actionBtns = this.isMobile ?
      [
        'align-justify',
        'delete',
        'link'] : [
          'align-left',
          'align-justify',
          'align-right',
          'extend-img',
          'delete',
          'caption',
          'rotate',
          'link'];

    for (const button of actionBtns) {
      const btn = document.createElement('img');

      btn.className = button;
      btn.setAttribute('src', `../../assets/svg/${button}.svg`);
      btn.setAttribute('style', `width: 22px; padding: 2px; margin: 1px 2px; cursor: pointer`);

      if (button.indexOf('align') >= 0 || button.indexOf('extend-img') >= 0) {
        btn.classList.add('align-image');

        btn.addEventListener('click', (e: Event) => {
          const alignButtons = actionButtonsWrapper.querySelectorAll('.align-image');

          for (const b of Array.from(alignButtons)) {
            b['style'].background = 'none';
          }

          btn.style.background = 'lightgrey';
          this.setImgProp(this.img, btn.classList.contains('extend-img') ? 'extend-img' : 'img-align', button.split('-')[1]);
        }, false);
      }

      if (button === 'caption') {
        btn.addEventListener('click', (e) => {
          this.toggleCaption(e);
        }, false);
      }

      if (button === 'delete') {
        btn.addEventListener('click', () => {
          this.removeImage();
        }, false);
      }

      if (button === 'rotate') {
        btn.addEventListener('click', () => {
          this.rotateImg();
        }, false);
      }

      if (button === 'link') {
        btn.addEventListener('click', () => {
          this.toggleLinkImgLink();
        }, false);
      }

      actionButtonsWrapper.appendChild(btn);
    }

    imageEditorPanel.appendChild(actionButtonsWrapper);
    rootWrapper.appendChild(imageEditorPanel);
  }

  setImgWrappersAndCaptions(visibleStatus?: boolean) {
    const parentNode = this.quill.root;
    const rootWrapper = this.quill.root.parentNode;
    const contentImg = parentNode.querySelectorAll('img');
    const imgWrappers = rootWrapper.querySelectorAll('.image-wrapper');

    if (contentImg.length !== imgWrappers.length) {
      for (const wrapper of imgWrappers) {
        wrapper.removeEventListener('click', this.editImg);
        wrapper.remove();
      }

      for (const img of contentImg) {
        const imageWrapper = document.createElement('div');
        const imageProps = this.getImgProps(img);
        this.ids++;

        img.setAttribute('id', this.ids.toString());
        img.style.minWidth = this.minImgWidth + 'px';

        imageWrapper.className = 'image-wrapper';
        imageWrapper.style.display = 'block';
        imageWrapper.style.position = 'absolute';
        imageWrapper.style.minWidth = this.minImgWidth + 'px';
        imageWrapper.style.zIndex = '1';
        imageWrapper.style.cursor = 'pointer';
        // imageWrapper.style.backgroundColor = '#ff00004a';
        // imageWrapper.style.visibility = visibleStatus ? 'visible' : 'hidden';
        imageWrapper.setAttribute('id', this.ids.toString());

        // set image wrapper position
        const captionSpan = document.createElement('span');
        captionSpan.className = 'caption-block';
        captionSpan.style.fontSize = '14px';
        captionSpan.style.display = 'none';
        captionSpan.style.position = 'absolute';
        captionSpan.style.alignItems = 'center';
        captionSpan.style.left = '0px';
        captionSpan.style.bottom = '0px';
        captionSpan.style.width = '100%';
        captionSpan.style.border = '0 none';
        captionSpan.style.borderBottom = '1px solid #0000002e';
        captionSpan.style.overflow = 'hidden';
        captionSpan.style.resize = 'none';
        captionSpan.style.minHeight = this.captionSize.height + 'px';
        captionSpan.contentEditable = 'true';

        setTimeout(() => {
          const imageProp = this.getImgProps(img);
          captionSpan.innerHTML = imageProp.captionText || this.defaultCaptionText;
          captionSpan.style.height = 'auto';
          captionSpan.style.height = `${captionSpan.scrollHeight}px`;
        }, 500);

        captionSpan.addEventListener('click', e => {
          e.stopPropagation();
        });

        captionSpan.addEventListener('input', (e) => {
          e.preventDefault();
          this.onValueChange(e);
        });

        captionSpan.addEventListener('keyup', (e) => {
          e.preventDefault();
          this.onValueChange(e);
        });

        captionSpan.addEventListener('paste', this.onTextPaste.bind(this), false);

        imageWrapper.appendChild(captionSpan);

        // resize rays
        for (let i = 0; i < 4; i++) {
          const ray = document.createElement('span');
          ray.style.fontSize = '0px';
          ray.innerHTML = i.toString();
          ray.className = 'ray-corner';
          ray.style.position = 'absolute';
          ray.style.background = '#3aada8';
          ray.style.width = '10px';
          ray.style.height = '10px';
          ray.style.display = 'none';
          ray.contentEditable = 'false';
          this.setUserSelect('none', ray);

          if (i === 0) {
            ray.classList.add('left-top');
            ray.style.left = '-5px';
            ray.style.top = '-5px';
            ray.style.cursor = 'nwse-resize';
          }

          if (i === 1) {
            ray.classList.add('right-top');
            ray.style.right = '-5px';
            ray.style.top = '-5px';
            ray.style.cursor = 'nesw-resize';
          }

          if (i === 2) {
            ray.classList.add('right-bottom');
            ray.style.right = `-5px`;
            ray.style.bottom = `-5px`;
            ray.style.cursor = 'nwse-resize';
          }

          if (i === 3) {
            ray.classList.add('left-bottom');
            ray.style.left = '-5px';
            ray.style.bottom = '-5px';
            ray.style.cursor = 'nesw-resize';
          }

          imageWrapper.appendChild(ray);

          ray.addEventListener('mousedown', this.initImageResize, false);
        }

        imageWrapper.addEventListener('click', this.editImg, false);

        rootWrapper.appendChild(imageWrapper);
        this.updateImage(img, imageWrapper);
        this.setCaptionsPositionAndSize();
        this.checkForImgLink(img);
        this.setImageLinkPosition(img);
        this.setElementsPositionAfterInitLoad(img);
      }
    } else {
      for (const img of contentImg) {
        const wrapper = this.getImgWrapperNode(img.getAttribute('id'));

        if (wrapper) {
          this.updateImage(img, wrapper);
          this.setCaptionsPositionAndSize();
          this.setImageLinkPosition(img);
          this.setElementsPositionAfterInitLoad(img);
        }
      }
    }
  }

  setElementsPositionAfterInitLoad(image: any): void {
    this.updateImage(image, this.getImgWrapperNode(image.id));
    this.setImageLinkPosition(image);
    const linkImgModule = this.getLinkImgWrapperNode(image);
    const captionImgModule = this.getCaptionImgWrapperNode(image);

    if (image['dataset']['loaded']) {
      if (linkImgModule) {
        linkImgModule.style.display = 'flex';
      }

      if (captionImgModule && this.getImgProps(image).captionText) {
        this.showCaptionInImgWrapper(image);
      }
    }
  }

  updateImage(sourceImg: any, wrapper: any) {
    const imgProps = this.getImgProps(sourceImg);

    wrapper.style.left = imgProps.pos.left.toString() + 'px';
    wrapper.style.top = imgProps.pos.top.toString() + 'px';
    wrapper.style.bottom = imgProps.pos.bottom.toString() + 'px';
    wrapper.style.right = imgProps.pos.right.toString() + 'px';
  }

  editImg(event: any) {
    this.hideEditor();
    const imgWrapper = event.target;
    const connectedImg = this.getImgNode(event.target.id);
    const editPanel = this.quill.root.parentNode.querySelector('.image-editor-panel');
    const imageProps = this.getImgProps(connectedImg);
    this.img = connectedImg;

    const rays = imgWrapper.querySelectorAll('.ray-corner');
    const captionSpan = imgWrapper.querySelector('.caption-block');

    if (captionSpan && imageProps.captionPos) {
      // captionSpan.style.display = 'block';
    }

    if (captionSpan && imageProps.captionText) {
      captionSpan.innerHTML = imageProps.captionText;
    }

    if (imgWrapper) {
      imgWrapper.style.border = '1px solid #3aada8';
    }

    if (rays) {
      for (const ray of rays) {
        ray.style.display = 'block';
      }
    }

    if (this.img && editPanel) {
      this.disableQuillPanel();
      this.setImageEditorPosition(this.img);
      this.updateImageEditorState(this.img);
    }
  }

  hideEditor() {
    const quillParent = this.quill.root.parentNode;
    const imageWrappers = quillParent.querySelectorAll('div[class="image-wrapper"]');
    const editPanel = quillParent.querySelector('.image-editor-panel');


    editPanel.style.display = 'none';

    for (const wrapper of imageWrappers) {
      const connectedImg = this.getImgNode(wrapper.id);

      if (connectedImg) {
        const imageProps = this.getImgProps(connectedImg);

        wrapper.style.border = '0 none';
        const rays = wrapper.querySelectorAll('.ray-corner');
        const caption = wrapper.querySelector('.caption-block');

        for (const ray of rays) {
          ray.style.display = 'none';
        }

        if (!imageProps.captionPos) {
          caption.style.display = 'none';
        }

        if (imageProps.link) {
          const linkModule = this.getLinkImgWrapperNode(connectedImg);

          linkModule.classList.remove('edit');
        }

        this.img = null;
      }
    }

    this.enableQuillPanel();
  }

  setImgProp(sourceImg: any, prop: string, value: string | number) {
    switch (prop) {
      case 'caption':
        sourceImg['dataset'][prop] = value;
        break;
      case 'caption-pos':
        sourceImg['dataset']['captionPos'] = value;
        break;
      case 'link':
        sourceImg['dataset']['link'] = value;
        break;
      case 'extend-img':
        sourceImg.style.float = 'none';
        sourceImg.style.width = '100%';
        sourceImg.parentNode.style.textAlign = 'initial';
        sourceImg.style.margin = '10px 0';
        break;
      case 'img-align':
        if (value === 'justify') {
          sourceImg.style.float = 'none';
          sourceImg.style.width = '50%';
          sourceImg.parentNode.style.textAlign = 'center';
          sourceImg.style.margin = '10px';
        } else {
          sourceImg.style.float = value;
          sourceImg.style.width = this.minImgWidth + 'px';
          sourceImg.style.margin = (value === 'left') ? '10px 10px 10px 0' : '10px 0 10px 10px';
          sourceImg.parentNode.style.textAlign = 'initial';
          this.setImgWidthInPercentage(sourceImg);
        }
        break;
    }
  }

  getImgProps(sourceImg: any) {
    if (sourceImg) {
      const imageRect = sourceImg.getBoundingClientRect();
      const qEditorPos = this.quill.root.getBoundingClientRect();

      const imgPos = {
        top: (qEditorPos.top - imageRect.top) < 0 ? (qEditorPos.top - imageRect.top) * -1 : qEditorPos.top - imageRect.top,
        left: (qEditorPos.left - imageRect.left) < 0 ? (qEditorPos.left - imageRect.left) * -1 : qEditorPos.left - imageRect.left,
        right: (qEditorPos.right - imageRect.right) < 0 ? (qEditorPos.right - imageRect.right) * -1 : qEditorPos.right - imageRect.right,
        bottom: (qEditorPos.bottom - imageRect.bottom) < 0 ?
          (qEditorPos.bottom - imageRect.bottom) * -1 : qEditorPos.bottom - imageRect.bottom
      };
      return {
        sourceImg,
        imgAlign: sourceImg.style.cssFloat ? sourceImg.style.cssFloat : 'justify',
        captionPos: sourceImg['dataset']['captionPos'] ? sourceImg['dataset']['captionPos'] : '',
        captionText: sourceImg['dataset']['caption'] ? sourceImg['dataset']['caption'] : '',
        pos: imgPos,
        link: sourceImg['dataset']['link'] ? sourceImg['dataset']['link'] : ''
      };
    }
  }

  initImageResize(e: any) {
    if (this.img) {
      this.rayPos = e;
      this.imgResizeNodeRectCopy = this.img.getBoundingClientRect();
      window.addEventListener('mousemove', this.startResizing, false);
      window.addEventListener('mouseup', this.stopResizing, false);
    }
  }

  startResizing(e: any) {
    const elClass = this.rayPos.target.classList;
    const imgWrapper = this.getImgWrapperNode(this.img.getAttribute('id'));
    let raysSide = '';

    if (elClass.contains('left-top') || elClass.contains('left-bottom')) {
      raysSide = 'left';
    }

    if (elClass.contains('right-top') || elClass.contains('right-bottom')) {
      raysSide = 'right';
    }

    if (imgWrapper && this.img && this.rayPos) {
      this.img.style.width =
          (raysSide === 'left') ?
            this.imgResizeNodeRectCopy.width - (e.clientX - this.rayPos.clientX) + 'px' :
            this.imgResizeNodeRectCopy.width + (e.clientX - this.rayPos.clientX) + 'px';

      this.setImageEditorPosition(this.img);
      this.setUserSelect('none');
    }
  }

  stopResizing() {
    window.removeEventListener('mousemove', this.startResizing);
    window.removeEventListener('mouseup', this.stopResizing);
    this.rayPos = null;
    this.imgResizeNodeRectCopy = null;
    this.setUserSelect('');

    if (this.img) {
      this.setImgWidthInPercentage(this.img);
    }
  }

  getImgWrapperNode(id: any) {
    return this.quill.root.parentNode.querySelector(`div[id="${id}"]`);
  }

  getImgNode(id: any) {
    return this.quill.root.parentNode.querySelector(`img[id="${id}"]`);
  }

  toggleCaption(e: any) {
    if (this.img) {
      if (e.target.classList.contains('selected')) {
        e.target.classList.remove('selected');
        e.target['style'].background = 'none';
        this.removeCaptionFromWrapperAndImg();
      } else {
        e.target.classList.add('selected');
        e.target['style'].background = '#33ada9';
        this.addCaptionToWrapperAndImg();
      }
    }
  }

  setUserSelect(value, el?: any) {
    [
      'userSelect',
      'mozUserSelect',
      'webkitUserSelect',
      'msUserSelect',
    ].forEach(prop => {
      if (el) {
        el.style[prop] = value;
      } else {
        this.quill.root.style[prop] = value;
        document.documentElement.style[prop] = value;
      }
    });
  }

  addCaptionToWrapperAndImg() {
    const connectedImg = this.img;
    const imageWrapper = this.getImgWrapperNode(this.img.id);
    const caption = imageWrapper.querySelector('.caption-block');

    if (imageWrapper && caption) {
      caption.style.alignItems = 'center';
      caption.style.display = 'flex';
      const imageProps = this.getImgProps(this.img);
      caption.innerHTML = imageProps.captionText ? imageProps.captionText : this.defaultCaptionText;
      this.setImgProp(connectedImg, 'caption', imageProps.captionText ? imageProps.captionText : this.defaultCaptionText);
      this.setImgProp(connectedImg, 'caption-pos', 'bottom');

      // set image padding to make space for caption
      connectedImg.style.paddingBottom = caption.scrollHeight + 'px';
    }
  }

  removeCaptionFromWrapperAndImg() {
    const connectedImg = this.img;
    const imageWrapper = this.getImgWrapperNode(this.img.id);
    const caption = imageWrapper.querySelector('.caption-block');

    if (caption) {
      this.setImgProp(connectedImg, 'caption', '');
      this.setImgProp(connectedImg, 'caption-pos', '');
      caption.style.display = 'none';
      caption.innerText = '';
      connectedImg.style.paddingBottom = '0';
    }
  }

  onValueChange(ev?: Event) {
    if (this.img || this.imgFromCaption) {
      const wrapper = this.getImgWrapperNode((this.img || this.imgFromCaption).id);
      const caption = wrapper.querySelector('.caption-block');

      if (caption) {
        const captionText = caption.innerHTML;
        this.setImgProp((this.img || this.imgFromCaption), 'caption', captionText);
        caption.style.height = 'auto';
        caption.style.height = `${caption.scrollHeight}px`;
        (this.img || this.imgFromCaption).style.paddingBottom = `${caption.scrollHeight}px`;
      }
    }
  }

  setImageEditorPosition(selectedImg: any) {
    const editPanel = this.quill.root.parentNode.querySelector('.image-editor-panel');
    const imageWrapper = this.getImgNode(selectedImg.id);
    const imgPos = this.getImgProps(this.img).pos;

    editPanel.style.display = 'block';
    editPanel.style.top = `${imgPos.top + 4}px`;
    if (imageWrapper) {
      editPanel.style.left = `${imgPos.left + (this.getImgProps(this.img).sourceImg.width / 2) - (editPanel.offsetWidth / 2)}px`;
    }
  }

  setCaptionsPositionAndSize() {
    const parentNode = this.quill.root;
    const rootWrapper = this.quill.root.parentNode;
    const contentImg = parentNode.querySelectorAll('img');
    const imgWrappers = rootWrapper.querySelectorAll('.image-wrapper');

    for (const img of contentImg) {
      const wrapper = this.getImgWrapperNode(img.getAttribute('id'));

      if (wrapper) {
        const caption = wrapper.querySelector('.caption-block');

        if (caption) {
          caption.style.height = 'auto';
          caption.style.height = `${caption.scrollHeight}px`;
          img.style.paddingBottom = `${caption.scrollHeight}px`;
        }
      }
    }
  }

  showCaptionInImgWrapper(img: any): void {
    const imgWrapper = this.getImgWrapperNode(img.id);

    if (imgWrapper) {
      const captionBlock = imgWrapper.querySelector('.caption-block');
      captionBlock.style.height = 'auto';
      captionBlock.style.display = 'flex';
      captionBlock.style.height = `${captionBlock.scrollHeight}px`;
      img.style.paddingBottom = `${captionBlock.scrollHeight}px`;
    } else {
      console.error('There is no image wrappr for image', img);
    }
  }

  updateImageEditorState(sourceImg: any) {
    const imageProps = this.getImgProps(sourceImg);

    if (imageProps) {
      const imageEditor = this.quill.root.parentNode.querySelector('.image-editor-panel');
      const alignButtons = imageEditor.querySelectorAll('.action-buttons-wrapper .align-image');
      const captionPosToggle = imageEditor.querySelector('.caption');
      const linkImgBtn = imageEditor.querySelector('.link');

      if (captionPosToggle) {
        captionPosToggle.classList.remove('selected');
        captionPosToggle.style.background = 'none';
      }

      for (const b of Array.from(alignButtons)) {
        b['style'].background = 'none';

        if (
          (imageProps.imgAlign === 'none' || !imageProps.imgAlign) &&
          sourceImg.parentNode.style.textAlign === 'center' &&
          b['classList'].contains('align-justify')) {
            b['style'].background = 'lightgrey';
        }

        if (
          imageProps.imgAlign === 'left' &&
          sourceImg.parentNode.style.textAlign !== 'center' &&
          b['classList'].contains('align-left')) {
            b['style'].background = 'lightgrey';
        }

        if (
          imageProps.imgAlign === 'right' &&
          sourceImg.parentNode.style.textAlign !== 'center' &&
          b['classList'].contains('align-right')) {
            b['style'].background = 'lightgrey';
        }

        if (
          ((imageProps.imgAlign === 'none' &&
          sourceImg.parentNode.style.textAlign !== 'center') || (!imageProps.imgAlign && !sourceImg.parentNode.style.textAlign)) &&
          b['classList'].contains('extend-img')) {
            b['style'].background = 'lightgrey';
        }
      }

      if (imageProps.captionPos && imageProps.captionText) {
        captionPosToggle.classList.add('selected');
        captionPosToggle.style.background = '#33ada9';
      }

      if (imageProps.link) {
        linkImgBtn.classList.add('selected');
        linkImgBtn.style.background = '#33ada9';
      } else {
        linkImgBtn.classList.remove('selected');
        linkImgBtn.style.background = 'none';
      }
    }
  }

  selectElementText(element: any) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  removeImage() {
    if (this.img) {
      this.img.remove();
      this.hideEditor();
      this.imgResizeNodeRectCopy = null;
      if (this.quill.root.parentNode.querySelector('.image-editor-panel')) {
        setTimeout(() => {
          this.quill.root.parentNode.querySelector('.image-editor-panel').style.display = 'none';
          this.img = null;
        }, 0);
      }
    }
  }

  rotateImg() {
    if (this.img && this.img.src) {
      this.toDataUrl(this.img.src).then(newDataUrl => {
        this.options.imgRotation(true);
        this.rotate64BaseImage(newDataUrl, 90, false).then((result: any) => {
          const base64 = 'data:image/png;base64,' + result;
          this.img.src = base64;

          setTimeout(() => {
            this.options.imgRotation(false);
          }, 1500);

          const intervalPeriod = 15000; // 15 sec

          clearTimeout(this.rotationInterval);
          this.rotationInterval = setTimeout(() => {
            console.log('upload file in bg after ' + intervalPeriod);
            fetch(base64)
              .then(res => res.blob())
              .then(blob => {
                const fileName = `${new Date().getTime()}.${blob.type.split('/')[1]}`;
                const fileToUpload = new File([blob], fileName, {type: blob.type});

                window.dispatchEvent(new CustomEvent('sendFileToUpload', {
                  detail: {
                    options: {
                      type: 'replace',
                      sourceFile: this.img
                    },
                    file: fileToUpload
                  }
                }));
              });
          }, intervalPeriod);
        });
      });
    }
  }

  toggleLinkImgLink(): void {
    if (this.getImgProps(this.img).link) {
      console.log('run edit mode');
    } else {
      const imgLinkModule = this.getLinkImgWrapperNode(this.img);

      if (imgLinkModule) {
        this.removeLinkImgWrapper(this.img);
      } else {
        this.addLinkImgWrapper(this.img, true);
      }
    }
  }

  checkForImgLink(img: any) {
    if (this.getImgProps(img).link && !this.getLinkImgWrapperNode(img)) {
      this.addLinkImgWrapper(img);
    }
  }

  addLinkImgWrapper(img: any, show?: boolean): void {
    const imgLinkWrapper = document.createElement('div');
    imgLinkWrapper.className = 'image-link-wrapper';

    imgLinkWrapper.addEventListener('click', e => {
      e.stopPropagation();
    });

    const icon = document.createElement('span');
    icon.className = 'icon';
    icon.innerHTML = `
      <svg height="12px" version="1.1" viewBox="0 0 20 12" width="20px" xmlns="http://www.w3.org/2000/svg" ` +
      `xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><desc/><defs/>` +
      `<g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" ` +
      `transform="translate(-380.000000, -300.000000)"><g id="link" transform="translate(380.000000, 300.000000)">` +
      `<path d="M6,7 L14,7 L14,5 L6,5 L6,7 L6,7 Z M1.9,6 C1.9,3.7 3.7,1.9 6,1.9 L9,1.9 L9,0 L6,0 C2.7,0 0,2.7 0,6 ` +
      `C0,9.3 2.7,12 6,12 L9,12 L9,10.1 L6,10.1 C3.7,10.1 1.9,8.3 1.9,6 L1.9,6 Z M14,0 L11,0 L11,1.9 L14,1.9 C16.3,1.9` +
      ` 18.1,3.7 18.1,6 C18.1,8.3 16.3,10.1 14,10.1 L11,10.1 L11,12 L14,12 C17.3,12 20,9.3 20,6 C20,2.7 17.3,0 14,0 L14,0 Z" ` +
      `id="Shape"/></g></g></g></svg>`;

    const linkInput = document.createElement('input');
    linkInput.type = 'text';
    linkInput.placeholder = 'http://';
    linkInput.value = this.getImgProps(img).link ? this.getImgProps(img).link : '';
    const buttonsWrapper = document.createElement('div');
    buttonsWrapper.className = 'buttons';

    const linkUrl = document.createElement('a');
    linkUrl.className = 'link-url';
    linkUrl.target = '_blank';
    linkUrl.innerHTML = this.getImgProps(img).link ? this.getImgProps(img).link : '';
    linkUrl.href = this.getImgProps(img).link ? this.getImgProps(img).link : '';

    const saveBtn = document.createElement('button');
    saveBtn.type = 'button';
    saveBtn.className = 'button--save';
    saveBtn.innerText = 'Save';

    const removeBtn = document.createElement('button');
    removeBtn.type = 'button';
    removeBtn.className = 'button--remove';
    removeBtn.innerText = 'Remove';

    const changeBtn = document.createElement('button');
    changeBtn.type = 'button';
    changeBtn.className = 'button--change';
    changeBtn.innerText = 'Change';

    imgLinkWrapper.appendChild(icon);
    imgLinkWrapper.appendChild(linkInput);
    imgLinkWrapper.appendChild(linkUrl);
    imgLinkWrapper.appendChild(buttonsWrapper);
    buttonsWrapper.appendChild(saveBtn);
    buttonsWrapper.appendChild(removeBtn);
    buttonsWrapper.appendChild(changeBtn);

    const imgWrapper = this.getImgWrapperNode(img.id);

    imgWrapper.appendChild(imgLinkWrapper);
    imgLinkWrapper.style.display = show === true ? 'flex' : 'none';

    const imgProps = this.getImgProps(img);

    if (!imgProps.link) {
      imgLinkWrapper.classList.add('edit');
    }

    saveBtn.addEventListener('click', e => {
      const inputValue = linkInput.value;
      linkUrl.innerHTML = linkInput.value;
      linkUrl.href = linkInput.value;
      this.setImgProp(img, 'link', inputValue);
      imgLinkWrapper.classList.remove('edit');
      this.updateImageEditorState(img);
    });

    removeBtn.addEventListener('click', e => {
      linkInput.value = '';
      linkUrl.innerHTML = '';
      linkUrl.href = '';
      this.setImgProp(img, 'link', '');
      this.removeLinkImgWrapper(img);
      this.updateImageEditorState(img);
    });

    changeBtn.addEventListener('click', e => {
      imgLinkWrapper.classList.add('edit');
    });

    this.setImageLinkPosition(img);
  }

  removeLinkImgWrapper(rootImg: any) {
    const imgLinkModuleNode = this.getLinkImgWrapperNode(rootImg);

    if (imgLinkModuleNode) {
      imgLinkModuleNode.remove();
    }
  }

  setImageLinkPosition(rootImg: any) {
    const imgLinkModule = this.getLinkImgWrapperNode(rootImg);
    const imgWrapper = this.getImgWrapperNode(rootImg.id);

    if (imgLinkModule) {
      imgLinkModule.style.top = (rootImg.height - 50) + 'px';
    }
  }

  getLinkImgWrapperNode(rootImg: any) {
    const imgWrapper = this.getImgWrapperNode(rootImg.id);
    const imgLinkModule = imgWrapper.querySelector('.image-link-wrapper');
    return imgLinkModule ? imgLinkModule : null;
  }

  getCaptionImgWrapperNode(rootImg: any) {
    const imgWrapper = this.getImgWrapperNode(rootImg.id);
    const imgCaptionModule = imgWrapper.querySelector('.caption-block');
    return imgCaptionModule ? imgCaptionModule : null;
  }

  rotate64BaseImage(base64data, degrees, enableURI) {
    return new Promise((resolve, reject) => {
      degrees = degrees ? degrees : 90;

      const canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'hidden-canvas');
      canvas.style.display = 'none';
      document.body.appendChild(canvas);

      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.src = (base64data.indexOf(',') === -1 ? 'data:image/png;base64,' : '') + base64data;
      image['onload'] = () => {
        const w = image.width;
        const h = image.height;
        const rads = degrees * Math.PI / 180;
        let c = Math.cos(rads);
        let s = Math.sin(rads);
        if (s < 0) {
          s = -s;
        }
        if (c < 0) {
          c = -c;
        }
        canvas.width = h * s + w * c;
        canvas.height = h * c + w * s;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(degrees * Math.PI / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        resolve(enableURI ? canvas.toDataURL() : canvas.toDataURL().split(',')[1]);
        document.body.removeChild(canvas);
      };

      image['onerror'] = () => {
        reject('Unable to rotate data\n' + image.src);
      };
    });
  }

  toDataUrl(url: any): Promise<any> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }

  disableResize() {
    if (this.img) {
      const imgWrapper = this.img.parentNode;
      const rays = imgWrapper.querySelectorAll('.ray-corner');

      imgWrapper.style.border = '1px solid lightgrey';

      for (const ray of rays) {
        ray.style.background = 'lightgrey';
        ray.removeEventListener('mousedown', this.initImageResize, false);
      }
    }
  }

  enableResize() {
    if (this.img) {
      const imgWrapper = this.img.parentNode;
      const rays = imgWrapper.querySelectorAll('.ray-corner');

      imgWrapper.style.border = '1px solid #33ada9';

      for (const ray of rays) {
        ray.style.background = '#33ada9';
        ray.addEventListener('mousedown', this.initImageResize, false);
      }
    }
  }

  setImgWidthInPercentage(img: any) {
    const contentWrapper = this.quill.root;
    const prc = (img.width / contentWrapper.clientWidth) * 100;
    this.img.style.width = prc + '%';
  }

  disableQuillPanel() {
    const quillToolbar = this.quill.container.parentNode.querySelector('.ql-toolbar');
    const toolbarButtons = quillToolbar.querySelectorAll('.ql-formats');

    for (const button of toolbarButtons) {
      button.style.opacity = '.3';
      button.style.pointerEvents = 'none';
    }
  }

  enableQuillPanel() {
    const quillToolbar = this.quill.container.parentNode.querySelector('.ql-toolbar');
    const toolbarButtons = quillToolbar.querySelectorAll('.ql-formats');

    for (const button of toolbarButtons) {
      button.style.opacity = '1';
      button.style.pointerEvents = 'auto';
    }
  }

  onCaptionClick(e: Event) {
    this.enableQuillPanel();
    this.lookForLinks(e);

    if (e.target['parentNode'] && e.target['parentNode'].classList.contains('image-wrapper')) {
      this.imgFromCaption = this.getImgNode(e.target['parentNode'].getAttribute('id'));
    }
  }

  onTextPaste(e: any) {
    e.preventDefault();

    let content;

    if (window['clipboardData']) {
      content = window['clipboardData'].getData('Text');
      if (window.getSelection) {
        const selObj = window.getSelection();
        const selRange = selObj.getRangeAt(0);
        selRange.deleteContents();
        selRange.insertNode(document.createTextNode(content));
      }
    } else if (e.clipboardData) {
      content = (e.originalEvent || e).clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content);
    }

    this.lookForLinks(e);

    if (e.target['parentNode'] && e.target['parentNode'].classList.contains('image-wrapper')) {
      this.imgFromCaption = this.getImgNode(e.target['parentNode'].getAttribute('id'));
    }
  }

  addLinkContainer() {
    const linkWrapperBg = document.createElement('div');
    linkWrapperBg.className = 'ql-link-wrapper-bg';
    const linkWrapper = document.createElement('div');
    linkWrapper.className = 'ql-link-wrapper';
    linkWrapper.innerHTML = '' +
        '<span>Enter URL: </span>' +
        '<a class="ql-preview" target="_blank" href="about:blank"></a>' +
        '<input type="text" placeholder="http://">' +
        '<a class="btn-action">Save</a>' +
        '<a class="btn-remove">Remove</a>' +
        '<a class="btn-close">Close</a>';

    linkWrapperBg.appendChild(linkWrapper);

    this.quill.root.parentNode.appendChild(linkWrapperBg);

    const btnAction = linkWrapper.querySelector('.btn-action');
    const btnRemove = linkWrapper.querySelector('.btn-remove');
    const btnClose = linkWrapper.querySelector('.btn-close');

    btnAction.addEventListener('click', this.addLinkToContent.bind(this));
    btnRemove.addEventListener('click', this.removeLinkFromContent.bind(this));
    btnClose.addEventListener('click', this.hideLinkContainer.bind(this));
  }

  showLinkContainer(targetElement?: any) {
    console.log('saved range: ', this.savedRange);
    if (!this.savedRange) {
      return;
    }
    const linkContainerBg = this.quill.root.parentNode.querySelector('.ql-link-wrapper-bg');
    const linkContainer = this.quill.root.parentNode.querySelector('.ql-link-wrapper');

    if (linkContainerBg) {
      linkContainerBg.classList.add('show');
      this.disableQuillPanel();

      if (this.savedRange.commonAncestorContainer.parentNode.tagName !== 'A') {
        linkContainer.classList.add('add');
      }

      const selectionRect = this.savedRange.getBoundingClientRect();
      const qEditorPos = this.quill.root.getBoundingClientRect();

      const selectionPos = {
        top: (qEditorPos.top - selectionRect.top) < 0 ?
          (qEditorPos.top - selectionRect.top) * -1 : qEditorPos.top - selectionRect.top,
        left: (qEditorPos.left - selectionRect.left) < 0 ?
          (qEditorPos.left - selectionRect.left) * -1 : qEditorPos.left - selectionRect.left,
        right: (qEditorPos.right - selectionRect.right) < 0 ?
          (qEditorPos.right - selectionRect.right) * -1 : qEditorPos.right - selectionRect.right,
        bottom: (qEditorPos.bottom - selectionRect.bottom) < 0 ?
          (qEditorPos.bottom - selectionRect.bottom) * -1 : qEditorPos.bottom - selectionRect.bottom
      };

      linkContainer.style.top = selectionPos.top + selectionRect.height + 'px';
      linkContainer.style.left = (10 + selectionPos.left - linkContainer.offsetWidth / 2) + 'px';

      if (targetElement && targetElement.nodeType) {
        const targetLink = targetElement.hasAttribute('href') ? targetElement.getAttribute('href') : false;
        if (targetLink) {
          linkContainer.querySelector('input').value = targetLink;
        }
      }
    }
  }

  hideLinkContainer() {
    const linkContainerBg = this.quill.root.parentNode.querySelector('.ql-link-wrapper-bg');
    const linkContainer = this.quill.root.parentNode.querySelector('.ql-link-wrapper');

    if (linkContainerBg) {
      linkContainerBg.classList.remove('show');
    }

    if (linkContainer) {
      // linkContainer.classList.remove('show');
      linkContainer.classList.remove('add');
      linkContainer.querySelector('input').value = '';
      linkContainer.removeAttribute('data-mode');
      this.imgFromCaption = null;
      this.savedRange = null;
      this.enableQuillPanel();
    }
  }

  addLinkToContent() {
    const linkContainer = this.quill.root.parentNode.querySelector('.ql-link-wrapper');
    const linkValue = linkContainer.querySelector('input').value;

    if (this.savedRange.commonAncestorContainer.parentNode.tagName === 'A') {
      // edit
      this.replaceSelectionWithHtml(this.prepareLink(linkValue, this.savedRange.commonAncestorContainer.nodeValue), true);
    } else {
      // add
      this.replaceSelectionWithHtml(this.prepareLink(linkValue, this.savedRange.toString()));
    }

    if (this.imgFromCaption) {
      this.setImgProp(this.imgFromCaption, 'caption', this.savedRange.commonAncestorContainer.innerHTML);
    }

    this.hideLinkContainer();
  }

  removeLinkFromContent() {
    if (this.savedRange) {
      const parentNode = this.savedRange.commonAncestorContainer.parentNode;
      const rangeContent = this.savedRange.commonAncestorContainer.nodeValue;

      parentNode.replaceWith(rangeContent);

      if (this.imgFromCaption) {
        this.setImgProp(this.imgFromCaption, 'caption', rangeContent);
      }
    }

    this.hideLinkContainer();
  }

  lookForLinks(e: any) {
    if (e && e.target.tagName === 'A') {
      let selection;

      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.getSelection) {
        selection = document.getSelection();
      }

      this.savedRange = selection.getRangeAt(0).cloneRange();
      this.showLinkContainer(e.target);
    }
  }

  replaceSelectionWithHtml(html: any, edit?: boolean) {
    let range;

    if (this.savedRange) {
      range = this.savedRange;
      range.deleteContents();

      const div = document.createElement('div');
      div.innerHTML = html;

      let child;
      const frag = document.createDocumentFragment();

      while ( (child = div.firstChild) ) {
        frag.appendChild(child);
      }

      if (edit && range.commonAncestorContainer.parentNode) {
        range.selectNode(range.commonAncestorContainer.parentNode);
        range.insertNode(frag);

        const parent = range.commonAncestorContainer.parentNode;
        parent.querySelectorAll('a')[1].remove();
      } else {
        range.insertNode(frag);
      }
    }
  }

  lookForImageEmptySpace() {
    this.quill.container.addEventListener('click', (e: any | Event) => {
      const targetNode = e.target;

      if (targetNode && targetNode.tagName === 'P') {
        const imgInNode = targetNode.querySelectorAll('img');

        if (imgInNode && imgInNode.length) {
          const nextNodeElement = targetNode.nextSibling;

          if ((
            nextNodeElement &&
            !targetNode.textContent &&
            nextNodeElement.querySelectorAll('br').length === 0) || !nextNodeElement) {
            const pNode = document.createElement('p');
            targetNode.after(pNode);
          }
        }
      }
    });
  }

  private prepareLink(url: string, name: string): string {
    return `<a href="${url}" target="_blank" title="${name}">${name}</a>`;
  }
}
