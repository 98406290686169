<a
  [href]="link"
  target="_blank"
  [title]="title ? title : 'Go to the external Notd subpage'"
  class="external-link">
  <svg class="feather feather-external-link" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/><polyline points="15 3 21 3 21 9"/><line x1="10" x2="21" y1="14" y2="3"/></svg>
  <span
    class="external-link__label"
    *ngIf="label">{{label}}</span>
</a>
