import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SideMenuModule} from '../../side-menu/side-menu.module';
import {InputTypeCounterModule} from '../../../app/shared/directives/input-type-counter.module';
import {NotdDropdownModule} from '../../../app/shared/notd-dropdown/notd-dropdown.module';
import {CircleProgressLoaderModule} from '../../../app/shared/circle-progress-loader/circle-progress-loader.module';
import {AnnotationModule} from '../../../app/shared/directives/directives.module';
import {ImageEditorModule} from '../../../app/shared/image-editor/image-editor.module';

import {NoteComponent} from './note.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faStickyNote, faSync} from '@fortawesome/free-solid-svg-icons';
import {faStickyNote as faStickyNoteO} from '@fortawesome/free-regular-svg-icons';
import {QuillModule} from 'ngx-quill';

@NgModule({
  declarations: [ NoteComponent ],
  imports: [
    RouterModule.forChild([{ path: '', component: NoteComponent }]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    FontAwesomeModule,
    InputTypeCounterModule,
    NotdDropdownModule,
    CircleProgressLoaderModule,
    QuillModule.forRoot(),
    AnnotationModule,
    ImageEditorModule
  ],
  exports: [ NoteComponent ]
})

export class NoteModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faStickyNote, faStickyNoteO);
  }
}
