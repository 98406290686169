import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SideMenuModule} from './side-menu/side-menu.module';
import {LoginModule} from './login/login.module';
import {NotesModule} from './notes/notes.module';
import {NoteModule} from './notes/note/note.module';
import {StreamsModule} from './streams/streams.module';
import {SalesModule} from './streams/sales/sales.module';
import {FlaggedModule} from './notes/flagged/flagged.module';
import {UserModule} from './user/user.module';
import {UserTransactionModule} from './user/transaction/user-transaction.module';
import {FieldEditorModule} from './shared/components/field-editor/field-editor.module';
// Services
import {AdminService} from './services/admin.service';
import {UserService} from '../app/shared/services/user_service';
import {CacheService} from '../app/shared/services/cache_service';
import {SearchService} from '../app/shared/services/search_service';
import {StreamService} from '../app/shared/services/stream_service';
import {NoteService} from '../app/shared/services/note_service';
import {CategoriesService} from '../app/shared/services/categories_service';
import {PayoutsModule} from './users/payouts/payouts.module';
// Interceptors
import {Util} from '../app/shared/util/util';
import {ApiInterceptor} from '../app/interceptors/api.interceptor';
// Guards
import {AuthGuard} from './quards/auth.guard';
import {LoginRedirectGuard} from './quards/login-redirect.guard';
import {UserResolve} from './resolvers/user-resolve.service';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // HttpModule,
    AppRoutingModule,
    DashboardModule,
    SideMenuModule,
    LoginModule,
    NotesModule,
    FlaggedModule,
    PayoutsModule,
    UserModule,
    UserTransactionModule,
    StreamsModule,
    FieldEditorModule,
    NoteModule,
    SalesModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      /* Multi is important or you will delete all the other interceptors */
      multi: true
    },
    AuthGuard,
    LoginRedirectGuard,
    AdminService,
    UserService,
    CacheService,
    Util,
    SearchService,
    UserResolve,
    StreamService,
    NoteService,
    CategoriesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
