import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'notd-boffice-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  buildDate = '';
  verHash = '';

  constructor(
      private router: Router,
      private route: ActivatedRoute) {
        this.buildDate = new Date(environment.buildDate).toUTCString();
        this.verHash = environment.gitHash;
       }
}
