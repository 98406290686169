import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../app/shared/services/user_service';
import {AdminService} from '../../services/admin.service';

import {PayoutStatus} from '../../../app/shared/types/payout_status';
import {PayoutDestination} from '../../../app/shared/types/payout_destination';
import {Payout} from '../../../app/shared/types/payout';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notd-boffice-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.scss']
})
export class PayoutsComponent implements OnInit {
  @ViewChild('changeStatusModal', {static: false}) changeStatusModal: ElementRef;

  payoutsList;
  searchParam: string;
  payoutsTypes = [];
  payoutDestinationType = PayoutDestination;
  payoutStatus = PayoutStatus;
  payoutDetailsModal: Payout;
  selectedStatusType = '1';
  modalStatusType: string;
  modalStaffNote: string;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(res => {
      this.searchParam = res.searchString;
    });

    this.setPayoutsTypes();
    this.getPayoutStatus(this.selectedStatusType);
  }

  changeStatus(event: Event, type?: string) {
    if (type === 'modal') {
      this.modalStatusType = event.target['value'];
    } else {
      this.selectedStatusType = event.target['value'];
      this.getPayoutStatus(event.target['value']);
    }
  }

  setPayoutsTypes() {
    const statusLng = 5;
    for (let i = 0; i < statusLng; i++) {
      this.payoutsTypes.push({
        label: PayoutStatus[i],
        val: i
      });
    }
  }

  openPayoutDetails(payout: any) {
    console.log('openPayoutDetails: ', payout);
  }

  getPayoutStatus(status: any) {
    this.adminService.getPayoutStatus(status).then(res => {
      this.payoutsList = res;
      this.payoutsList.map((payload: any) => {
        this.userService.getUserProfile(payload.userId).then(userResp => {
          Object.assign(payload, {
            user: userResp
          });
        });
      });
    });
  }

  openEditStatus(payout: Payout) {
    this.modalStatusType = '';
    this.modalStaffNote = payout.staffNotes || '';
    this.payoutDetailsModal = payout;

    const modalOptions: NgbModalOptions = {};

    const refModalConf = this.modalService.open(this.changeStatusModal, modalOptions);

    refModalConf.result.then(resp => {
      if (resp === 'save') {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.adminService.updatePayout(payout.id, this.modalStaffNote, this.modalStatusType).then(res => {
          this.selectedStatusType = this.modalStatusType;
          this.getPayoutStatus(this.modalStatusType);
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  openDocFile(payout: Payout, fileType: string) {
    return document.location.assign(this.adminService[fileType === 'tax' ?
      'getUserTaxDocPath' : 'getUserIdentityDocPath'](payout['user']['id']));
  }
}
