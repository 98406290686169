import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'notd-dropdown',
  templateUrl: './notd-dropdown.component.html',
  styleUrls: ['./notd-dropdown.component.scss']
})
export class NotdDropdownComponent implements AfterViewInit {
  @Input() objectConvert: any;
  @Input() options: any;
  @Input() buttonLabel: string;
  @Input() staticButtonLabel: boolean;
  @Input() selectedPropertyBack: any;
  @Input() position: string;
  @Output() elementSelected: EventEmitter<any> = new EventEmitter();

  toggleStatus = false;

  constructor(
    private eRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // console.log('click inside');
    } else {
      // console.log('click outside');
      this.hideDropdown();
    }
  }

  ngAfterViewInit() {
    this.setDropdownPosition();
  }

  clickOnListElement(element: any) {
    this.elementSelected.emit(this.selectedPropertyBack ? element[this.selectedPropertyBack] : element);

    if (!this.staticButtonLabel) {
      this.buttonLabel = element[this.objectConvert['label']];
    }
    this.hideDropdown();
  }

  dropdownToggle() {
    this.toggleStatus = !this.toggleStatus;
  }

  setDropdownPosition() {
    const button = this.eRef.nativeElement.querySelector('.notd-dropdown__button');
    const dropdown = this.eRef.nativeElement.querySelector('.notd-dropdown__list');

    if (button) {
      const buttonHeight = button.offsetHeight;
      dropdown.style.top = buttonHeight + 5 + 'px';
    }

    if (this.position) {
      if (this.position === 'right') {
        dropdown.style[this.position] = 0;
      }

      if (this.position === 'center') {
        dropdown.style.left = '50%';
        dropdown.style.transform = 'translate(-50%, 0)';
        dropdown.style.minWidth = '100%';
      }
    }
  }

  hideDropdown() {
    this.toggleStatus = false;
  }
}
