<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    Note details: <strong>{{note?.name}}</strong><br /><span *ngIf="note?.id" class="small color-carmine"> (id: {{note?.id}})</span>
    <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
  </h3>
  <div class="loading-spinner-holder" *ngIf="loadingNote">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div class="profile-wrapper" *ngIf="!loadingNote">
    <div class="profile-info">
      <div class="avatar">
        <img *ngIf="note?.thumbnailImage" class="rounded" [src]="note.thumbnailImage" />
        <fa-icon *ngIf="!note?.thumbnailImage" [icon]="['far', 'sticky-note']"></fa-icon>
        <div class="buttons">
          <button class="btn red" (click)="deleteNote(note.id)">Delete note</button>
        </div>
      </div>
      <div class="profile-details">
        <div class="text-label mb-10">
          <label>State:</label>
          <span class="text">
            <span class="tag link" (click)="setNoteState(noteState[0])" title="Change state to {{noteState[0]}}" [ngClass]="{'active' : note['state'] === noteState[0] ? true : false}">{{noteState[0]}}</span>
            <span class="tag link" (click)="setNoteState(noteState[1])" title="Change state to {{noteState[1]}}" [ngClass]="{'active' : note['state'] === noteState[1] ? true : false}">{{noteState[1]}}</span>
            <span class="tag link" (click)="setNoteState(noteState[2])" title="Change state to {{noteState[2]}}" [ngClass]="{'active' : note['state'] === noteState[2] ? true : false}">{{noteState[2]}}</span>
          </span>
        </div>
        <div class="text-label mb-10" *ngIf="note.flags && note.flags.length">
          <label>Flags:</label>
          <span class="text">
            <span
              class="tag link"
              *ngFor="let flag of note.flags"
              (click)="flagAction(flag)" title="Make flag action to">{{flag.state}}</span>
          </span>
        </div>
        <div class="text-label">
          <label>Note created:</label>
          <span class="text">{{note.created | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="text-label">
          <label>Note name:</label>
          <span class="text">{{note.name}}</span>
        </div>
        <div class="text-label">
          <label>Stream:</label>
          <a class="text link color-blue rem-1" [routerLink]="['/stream', note.channelId]" *ngIf="note.streamName">{{note.streamName}}</a>
        </div>
        <div class="text-label">
          <label>Note owner:</label>
          <span class="text" *ngIf="!note['userName']">none</span>
          <a class="text link color-blue rem-1" [routerLink]="['/user', note.userId]" *ngIf="note['userName']">{{note['userName']}}</a>
        </div>
        <div class="text-label">
          <label>Debaters:</label>
          <span class="tag link"
                [hidden]="noteDebaters.length === 0"
                title="Show {{debater.userName}} profile"
                [routerLink]="['/user', debater.userId]"
                *ngFor="let debater of noteDebaters">{{debater.userName}}</span>
          <span class="text" *ngIf="noteDebaters.length === 0">none</span>
        </div>
        <div class="text-label">
          <label>Tags:</label>
          <span class="tag" *ngFor="let tag of noteTags">{{tag}}</span>
        </div>
        <div class="text-label">
          <label>Price:</label>
          <span class="text">{{note['price'] / 100}}</span>
        </div>
        <div class="text-label">
          <label>Avg rating:</label>
          <span class="text">{{note['ratingAvg'] | number: '1.1-2'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="note-content">
    <h3 class="sub-header mt-20">Note content</h3>
    <form novalidate class="note-content-form" [formGroup]="noteForm">
      <div class="form-group note-title-wrapper"
           id="note-title"
           [ngClass]="{ 'error' : noteForm.get('title').invalid && noteForm.get('title').touched }">
        <span class="label">Note title:</span>
        <input type="text"
               class="form-control"
               formControlName="title"
               [chartsCounter]="{max: 80}"
               placeholder="Title">
        <span class="form-error" *ngIf="noteForm.get('title').hasError('required')">Please enter title of the note.</span>
        <span class="form-error" *ngIf="noteForm.get('title').hasError('validateNoteName')">Please enter different name of the note</span>
        <span class="form-error" *ngIf="noteForm.get('title').hasError('maxlength')">Note title can be maximum 80 characters long.</span>
      </div>
      <div class="form-group thumbnail-uploader">
        <span class="label">Note thumbnail:</span>
        <image-editor
          (uploaded)="savedThumbnail($event)"
          [currentImg]="note?.thumbnailImage || currentNoteThumb || '../assets/svg/new-note-blue.svg'"
          [imgHeight]="300"
          [imgWidth]="300"
          [imgOutputWidth]="300"
          [imgOutputHeight]="300"
          [customClass]="(note?.thumbnailImage || currentNoteThumb ) ? 'note-thumbnail' : 'note-thumbnail-default'"
          [imgAlt]="note?.name"></image-editor>
        <span class="error-message" *ngIf="thumbnailErrors.limit">File size exceeds limit of 3MB</span>
        <span class="error-message" *ngIf="thumbnailErrors.other">{{thumbnailErrors.other}}</span>
      </div>
      <div class="form-group note-categories" [ngClass]="{ 'error' : !tagsLimitValid }" id="note-tags">
        <div class="dropdown" *ngFor="let type of categoriesData; let i = index">
          <notd-dropdown
            [ngClass]="{ 'selected' : type.selected }"
            [buttonLabel]="type.typeLabel"
            [staticButtonLabel]="true"
            [objectConvert]="{label: 'label', selected: 'selected'}"
            (elementSelected)="collectCategories({form: type.type, category: $event.category})"
            [options]="type.categories">
          </notd-dropdown>
        </div>
        <span class="form-error display-block">You can select from {{categoriesLimit[0]}} to {{categoriesLimit[1]}} categories.</span>
      </div>
      <div class="form-group tags" *ngIf="selectedCategories.length > 0">
        <div class="label">Selected tags:</div>
        <div class="selected-tags">
          <span class="tag" *ngFor="let tag of selectedCategories;">
              {{ getTagLabel(tag) }}
              <svg (click)="collectCategories(tag)" height="16px" viewBox="0 0 32 32" width="16px">
                  <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
              </svg>
          </span>
        </div>
      </div>
      <div class="form-group"
           id="note-lead-content"
           [ngClass]="{ 'error' : noteForm.get('leadContent').invalid && noteForm.get('leadContent').touched }">
        <span class="label">Summary content (visible by anyone):</span>
        <textarea class="form-control lead-content" formControlName="leadContent" placeholder="Write the excerpt of your note here, it can be seen by anyone"></textarea>
        <span class="form-error">Please enter summary content of the note.</span>
      </div>
      <div class="form-group"
           id="note-premium-content"encapsulation
           [ngClass]="{ 'error' : noteForm.get('content').invalid && noteForm.get('content').touched }">
        <span class="label">Premium content:</span>
        <circle-progress-loader
          [progress]="imagePreloader.progress"
          [ngClass]="{'show': imagePreloader.show}"></circle-progress-loader>
        <quill-editor formControlName="content"
                      class="quill-editor"
                      name="premiumContent"
                      annotations
                      [note]="note"
                      (statusChange)="annotationStatusChange($event)"
                      (errorMessage)="showErrorMessage($event.title, $event.message)"
                      [allowAnnotations]="true"
                      [containerSelector]="'ql-editor'"
                      theme="snow"
                      placeholder="The content here is only visible to subscribers" [modules]="quillConfig"></quill-editor>
        <!--<textarea class="form-control" formControlName="content" placeholder="Note Content"></textarea>-->
        <span class="form-error">
          Please enter content of the note.
        </span>
      </div>
      <div class="form-group">
        <button type="button"
                (click)="saveChanges()"
                class="btn btn-primary post mr-2 button-spinner"
                [ngClass]="{'button-spinner' : savingPostProgress}">Save changes</button>
      </div>
    </form>
  </div>
</div>

<ng-template #flagActionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Note flag action</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>What action would you like to make?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('accept')">Accept</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('reject')">Reject</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('close')">Cancel</button>
  </div>
</ng-template>


