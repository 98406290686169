<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
  <a class="app-logo" [routerLink]="['/']">
    <img src="../../assets/logo-dark.png">
    <span class="label">Backoffice</span>
  </a>

  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link true-link" [hidden]="isLoggedIn" (click)="login()">
        <i class="fa fa-fw fa-sign-in"></i>
        <span class="nav-link-text">Login</span>
      </a>
      <a class="nav-link true-link" [hidden]="!isLoggedIn" (click)="logout()">
        <i class="fa fa-fw fa-sign-out"></i>
        <span class="nav-link-text">Logout</span>
      </a>
    </li>
  </ul>

</nav>
