import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';


interface Chart {
  min?: number;
  max: number;
}

interface DisplayPosition {
  top?: number;
  right?: number;
}
@Directive({
  selector: '[chartsCounter]',
})
export class InputTypeCounterDirective implements OnInit {
  @Input() chartsCounter: Chart;
  @Input() chartPosition: DisplayPosition;
  private counterContainer: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef) {}

  @HostListener('keyup', ['$event'])
  inputKeyPressed(event: Event) {
    this.setCounters(event.target['value'].length, this.chartsCounter);
  }

  ngOnInit() {
    this.createCounter();
  }

  createCounter() {
    this.el.nativeElement.style.paddingRight = this.chartsCounter.min ? '88px' : '60px'; // 60
    this.counterContainer = document.createElement('div');

    this.counterContainer.style.position = 'absolute';
    this.counterContainer.style.padding = '4px';
    this.counterContainer.style.backgroundColor = 'rgba(128, 128, 128, .14)';
    this.counterContainer.style.fontSize = '12px';
    this.counterContainer.style.color = 'black';
    this.counterContainer.style.borderRadius = '5px';
    this.counterContainer.classList.add('chart-input-counter');

    const parentNode = this.el.nativeElement.parentNode;

    parentNode.style.position = 'relative';

    const inputRect = this.el.nativeElement.getBoundingClientRect();
    const parentRect = parentNode.getBoundingClientRect();

    this.counterContainer.style.right = this.chartPosition && this.chartPosition.right ?
      `${this.chartPosition.right}px` : `${parentRect.right - inputRect.right + 4}px`;
    this.counterContainer.style.top = this.chartPosition && this.chartPosition.top ?
    `${this.chartPosition.top}px` : `${(parentRect.top - inputRect.top) + (inputRect.height / 2 - 26 / 2)}px`;

    this.renderer.appendChild(parentNode, this.counterContainer);
    this.setCounters(this.el.nativeElement.value.length, this.chartsCounter);
  }

  setCounters(currentNum: number, limit: Chart) {
    this.counterContainer.innerHTML = limit.min ? `${limit.min} / ${currentNum} / ${limit.max}` : `${currentNum} / ${limit.max}`;
    this.counterContainer.style.color = (currentNum > limit.max || currentNum < limit.min) ? 'red' : 'black';
  }
}
