/* eslint-disable prefer-const */
/* eslint-disable one-var */
/* eslint-disable @typescript-eslint/naming-convention */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
import {AdminService} from '../services/admin.service';

import {User} from '../../app/shared/types/user';
import {StreamKind} from '../../app/shared/types/stream_kind';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';


@Component({
  selector: 'notd-boffice-streams',
  templateUrl: './streams.component.html',
  styleUrls: ['./streams.component.scss']
})
export class StreamsComponent implements OnInit {
  streamsList = [];
  loading = false;
  streamKind = StreamKind;
  public searchQuery: string;
  public streamPagination: {
    total: number;
    offset: number;
    maxSize: number;
    pageSize: number;
  } = {
    total: 300,
    offset: 0,
    maxSize: 20,
    pageSize: 30
  };

  private csvData = [];

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.getStreams();
  }

  public pageChangedEvent(page: any): void {
    this.streamPagination.offset = this.streamPagination.pageSize * (page-1);
    this.getStreams();
  }

  public submitStreamSearch(event: Event): void {
    this.searchQuery = event.target[0].value;
    this.streamPagination.offset = 0;
    this.getStreams();
  }

  public exportDataToCsv(): void {
    const fileName = 'streams-list.csv';
    let data, link;

    let csv = this.convertArrayOfObjectsToCSV({
      data: this.csvData
    });

    if (csv == null) {
      return;
    }

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }

    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', fileName);
    link.click();
  }

  public clearSearch(inputElement: HTMLInputElement) {
    inputElement.value = '';
    this.searchQuery = '';
    this.streamPagination.offset = 0;
    this.getStreams();
  }

  private getStreams(): void {
    this.loading = true;
    const params = {
      q: this.searchQuery,
      size: this.streamPagination.pageSize,
      fromOffset: this.streamPagination.offset,
    };
    this.adminService.getStreams(params).then((res: any) => {
      this.streamsList = res.channels;
      this.streamPagination.total = res.total || this.streamPagination.pageSize || 0;
      this.loading = false;
      this.streamsList.map(stream => {
        stream.externalLink = `${environment.urlBasePrefix}/streams/${stream.id}/view/${stream.indexedName || stream.name}`;

        this.userService.getUserProfile(stream.userId).then((userDetail: User) => {
          Object.assign(stream, {
            userName: userDetail.displayName,
            userEmail: userDetail.email1
          });

          this.csvData.push({
            'Stream Name': stream.name,
            Owner: userDetail.displayName,
            'Owner e-mail': userDetail.email1,
            Posts: stream.numPosts,
            Status: 'test',
            Followers: stream.followers,
            Members: stream.numMembers,
            Subs: stream.numSubs,
            Rating: stream.ratingAvg,
            Purchases: stream.numPurchases,
            Views: stream.numViews
          });
        });
      });
    });
  }

  private convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) {
          result += columnDelimiter;
        }

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

}
