<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    Flagged Notes
    <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
  </h3>
  <div class="search-for-note">
    <form>
      <label>Select notes flag:</label>
      <select (change)="changeNoteState($event)">
        <option *ngFor="let state of stateTypes; let i = index" [selected]="i === 0">{{state}}</option>
      </select>
    </form>
  </div>
  <section class="flagged-content">
    <section *ngIf="loading" class="bo__loading">
      <span class="bo__loading__spinner"></span>
      <p class="bo__loading__label">Loading flagged notes...</p>
    </section>
    <table *ngIf="!loading && notesList.length" class="bo__table text--centered">
      <thead>
        <tr>
          <th>
            <column-sorter [text]="'Title'" [list]="notesList" [field]="'name'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Flags'" [list]="notesList" [field]="'flag.reason'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Created'" [list]="notesList" [field]="'created'"></column-sorter>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of notesList">
          <td>
            <div class="table-note-wrapper">
              <a *ngIf="note.thumbnailImage" [routerLink]="['/note', note.id]" class="title-wrapper__img link" title="{{note.name}}">
                <img
                [src]="note.thumbnailImage"
                [alt]="note.name" />
              </a>
              <a [routerLink]="['/note', note.id]" class="link black--link" title="{{note.name}}">{{ note.name }}</a>
            </div>
          </td>
          <td class="text--centered">
            <fa-icon *ngIf="!note.flags" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <div *ngIf="note.flags">
              <a title="Click for action" class="notd__label label--blue" *ngFor="let flag of note.flags">{{flag.state}}</a>
            </div>
            <span class="notd__label label--blue" *ngIf="note.flags && note.flags.length === 0">none</span>
          </td>
          <td>
            {{ note.created | date: 'MMM dd, yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="notesList.length === 0 && !this.loading">
      <div>
        There is no flagged notes so far.
      </div>
    </ng-container>
  </section>
</div>
