import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'notd-boffice-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  userChange;

  constructor(
      private adminService: AdminService,
      private userService: UserService,
      private router: Router,
      private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.userChange = this.userService.curUser$.subscribe(() => {
      this.isLoggedIn = this.userService.isLoggedIn();
    });
  }

  login() {
    this.userService.login().then(() => {
      this.router.navigate(['/']);
    });
  }

  logout() {
    this.userService.logout().then(() => {
      this.isLoggedIn = false;
      this.router.navigate(['/login']);
    });
  }
}
