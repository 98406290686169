<svg preserveAspectRatio="xMidYMid meet"
     [attr.height]="radius * 2"
     [attr.width]="radius * 2"
     viewBox="0 0 80 80">
  <circle class="progress__meter"
          [attr.cx]="radius"
          [attr.cy]="radius"
          [attr.r]="radius - stroke"
          fill="none"
          [attr.stroke-width]="stroke"/>
  <circle class="progress__value"
          [attr.cx]="radius"
          [attr.cy]="radius"
          [attr.r]="radius - stroke"
          fill="none"
          stroke-linecap="round"
          [attr.stroke-width]="stroke"
          [style.strokeDasharray]="circumference"
          [style.strokeDashoffset]="dashoffset"/>
  <!--<circle class="moving-circle"-->
          <!--[attr.cx]="radius"-->
          <!--[attr.cy]="radius"-->
          <!--[attr.r]="radius - stroke" [attr.stroke-width]="stroke"/>-->
  <image preserveAspectRatio="none" height="60" width="60" xlink:href="assets/svg/photo.svg" x="10" y="-70"></image>
</svg>

