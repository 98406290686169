import { catchError } from 'rxjs/operators';
import {Note} from '../types/note';
import * as Sentry from '@sentry/browser';

export class ImageUpload {
  quill: any;
  options: any;

  constructor(
    quill,
    options = {}) {
    this.quill = quill;
    this.options = options;
    this.quill
      .getModule('toolbar')
      .addHandler('image', this.selectLocalImage.bind(this));

    this.handleDrop = this.handleDrop.bind(this);
    this.quill.root.addEventListener('drop', this.handleDrop, false);

    this.sendToServer = this.sendToServer.bind(this);
    this.disableQuill = this.disableQuill.bind(this);
    this.enableQuill = this.enableQuill.bind(this);

    window.addEventListener('sendFileToUpload', e => {
      const fileToUpload = e['detail'].file;

      if (/^image\//.test(fileToUpload.type)) {
        this.sendToServer(fileToUpload, e['detail'].options);
      }
    }, false);
  }

  /**
   * Select local image
   */
  selectLocalImage() {
    if (this.options.isNoteIdEmpty()) {
      this.options.showErrorMessage();
    } else {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      console.log('input: ', input);

      input.addEventListener('change', () => {
        const file = input.files[0];

        console.log('on change: ', file);

        if (/^image\//.test(file.type)) {
          this.sendToServer(file);
        } else {
          console.warn('You could only upload images.');
          this.options.imageFileError();
        }
      });
    }
  }

  sendToServer(file: File, options?: any) {
    console.log('sendToServer', file);
    const noteId = this.options.getNoteId();

    if (noteId) {
      if (!options) {
        this.disableQuill();
        this.options.imageFileUploading();
      }
      this.options.noteService.getMediaFile(file.name, file.type, noteId).then(res => {
        const uploadUrl = res.upload_url;
        Sentry.configureScope((scope) => {
          scope.setExtra('note ID', noteId);
          scope.setExtra('file', file);
          scope.setExtra('file URL', res.upload_url || res);
        });
        this.options.noteService.uploadAmazonFile(file, uploadUrl).subscribe(response => {
          if (response.status === 'progress') {
            this.options.imageFileUploadedProgress(response.message);
          }

          if (response.status === 'response') {
            let stopCounter = 0;
            const premiumMediaInterval = setInterval(() => {
              stopCounter++;
              this.options.noteService.getNoteInfo(noteId, true).then((noteInfo: Note) => {
                console.log('noteInfo.premMedia: ', noteInfo.premMedia);
                if (noteInfo.premMedia && noteInfo.premMedia.length > 0) {
                  for (const media of noteInfo.premMedia) {
                    const fileName = file.name.replace(/[^a-z0-9.-]/gi, '-');
                    // console.log('original fileName: ', file.name);
                    // console.log('search fileName: ', fileName);
                    // console.log('media: ', media);
                    // console.log('media key: ', media['key']);
                    // console.log('media index: ', media['key'].toString().indexOf(fileName) > 0);
                    if (media['key'].toString().indexOf(fileName) > 0) {
                      clearInterval(premiumMediaInterval);
                      if (options && options.type === 'replace' && options.sourceFile) {
                        this.replace(media['download'], options.sourceFile);
                      } else {
                        this.insert(media['download']);
                        this.enableQuill();
                        this.options.imageFileUploaded();
                      }
                    }
                  }
                }
              }, err => {
                console.error(err.error || err);
                this.options.imageFileError();
                this.enableQuill();
              });

              if (stopCounter > 15) {
                this.options.imageFileUploaded();
                this.options.imageFileError();
                Sentry.captureMessage('Error: file not found after 40 sec of intervals');
                Sentry.configureScope((scope) => {
                  scope.setExtra('note ID', noteId);
                  scope.setExtra('file', file);
                  scope.setExtra('media files array', res);
                });

                console.log('Error: file not found after 40 sec of intervals');
                console.log('notd id: ', noteId);
                console.log('file: ', file);
                console.log('media files array: ', res);

                clearInterval(premiumMediaInterval);
                this.enableQuill();
              }
            }, 2000);
          }

          if (response.status === 'error') {
            this.options.imageFileError();
            this.enableQuill();
          }
        }, err => {
          console.error(err.error || err);
          this.options.imageFileError();
          this.enableQuill();
        });
      }, err => {
        console.error(err.error || err);
        this.options.imageFileError();
        this.enableQuill();
      });
    }
  }

  handleDrop(evt) {
    evt.preventDefault();
    if (this.options.isNoteIdEmpty()) {
      this.options.showErrorMessage();
    } else {
      if (evt.dataTransfer && evt.dataTransfer.files && evt.dataTransfer.files.length) {
        if (document.caretRangeFromPoint) {
          const selection = document.getSelection();
          const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
          if (selection && range) {
            selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
          }
        }
        const file = evt.dataTransfer.files[0];

        if (/^image\//.test(file.type)) {
          this.sendToServer(file);
        } else {
          console.warn('You could only upload images.');
          this.options.imageFileError();
        }
      }
    }
  }

  insert(dataUrl) {
    const index = (this.quill.getSelection() || {}).index >= 0 ? (this.quill.getSelection() || {}).index : this.quill.getLength();

    this.quill.insertEmbed(index, 'image', dataUrl, 'user');
  }

  replace(mediaUrl, sourceFile) {
    console.log(mediaUrl, sourceFile);
    sourceFile.src = mediaUrl;
  }

  disableQuill(message?: string) {
    const quillContainer = this.quill.container.parentNode;

    quillContainer.style.position = 'relative';
    quillContainer.style.display = 'block';

    const quillPanel = document.createElement('section');

    quillPanel.className = 'quill-image-panel';
    quillPanel.style.top = '0';
    quillPanel.style.left = '0';
    quillPanel.style.right = '0';
    quillPanel.style.bottom = '0';
    quillPanel.style.position = 'absolute';
    quillPanel.style.display = 'flex';
    quillPanel.style.alignItems = 'center';
    quillPanel.style.justifyContent = 'center';
    quillPanel.style.background = 'rgba(256, 256, 256, .8)';
    quillPanel.style.zIndex = '1';

    quillContainer.appendChild(quillPanel);

    if (message) {
      const panelMessage = document.createElement('span');

      panelMessage.innerText = message;
      quillPanel.appendChild(panelMessage);
    }
  }

  private enableQuill(): void {
    const quillContainer = this.quill.container.parentNode;

    if (quillContainer.querySelector('.quill-image-panel')) {
      quillContainer.querySelector('.quill-image-panel').remove();
    }
  }
}
