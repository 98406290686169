<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    Notes list <span class="searched-param" *ngIf="searchParam">(result of: {{searchParam}})</span>
    <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
  </h3>
  <div class="search-for-note">
    <form (ngSubmit)="submitNoteSearch($event)">
      <label for="search-note">Search for note</label>
      <input type="text" class="form-control" id="search-note" placeholder="Search for query..">
      <button type="submit" class="btn btn-primary mb-2">Search</button>
    </form>
    <div class="other-buttons">
      <button type="submit" class="btn btn-warning" (click)="deleteSelectedNotes()">Delete selected</button>
    </div>
  </div>
  <section class="featured-content">
    <section *ngIf="loading" class="bo__loading">
      <span class="bo__loading__spinner"></span>
      <p class="bo__loading__label">Loading notes...</p>
    </section>
    <ng-container *ngIf="notesList.length === 0 && !this.loading">
      <div>
        There is no data in this criteria.
      </div>
    </ng-container>
    <table *ngIf="!loading && notesList.length" class="bo__table text--centered">
      <thead>
        <tr>
          <th>
            <column-sorter [text]="'Title'" [list]="notesList" [field]="'name'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Stream'" [list]="notesList" [field]="'channelName'"></column-sorter>
          </th>
          <th class="label">
            <span>External link</span>
          </th>
          <th>
            <column-sorter [text]="'Featured'" [list]="notesList" [field]="'featured'"></column-sorter>
          </th>
          <th class="label">
            <span>Flags</span>
          </th>
          <th>
            <column-sorter [text]="'Price'" [list]="notesList" [field]="'price'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Rating'" [list]="notesList" [field]="'ratingAvg'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Views'" [list]="notesList" [field]="'viewCount'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'State'" [list]="notesList" [field]="'state'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Created'" [list]="notesList" [field]="'created'"></column-sorter>
          </th>
          <th class="label">
            <span>Multi-select</span>
          </th>
          <th class="label">
            <span>Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of notesList">
          <td class="text-left">
            <div class="table-note-wrapper">
              <a *ngIf="note.thumbnailImage" [routerLink]="['/notes', note.id, note.indexedName]" class="title-wrapper__img link" title="{{note.name}}">
                <img
                [src]="note.thumbnailImage"
                [alt]="note.name" />
              </a>
              <a [routerLink]="['/note', note.id]" class="link black--link" title="{{note.name}}">{{note.name}}</a>
            </div>
          </td>
          <td>
            <a [routerLink]="['/stream', note.channelId]" class="link black--link" title="{{note.channelName}}">{{note.channelName}}</a>
          </td>
          <td>
            <a class="notd__button--v2 button--green" [href]="note.externalLink">Show note on Notd</a>
          </td>
          <td>
            <select (change)="changeFeaturePriority($event, note)">
              <option *ngFor="let prio of featuredPriorities; let i = index" [selected]="i === note.featured">{{prio}}</option>
            </select>
          </td>
          <td>
            <fa-icon *ngIf="!note.states" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <div *ngIf="note.states">
              <a (click)="flagNote(state)" title="Click for action" class="btn blue small" *ngFor="let state of note.states">{{state.state}}</a>
            </div>
            <span class="notd__label label--blue" *ngIf="note.states && note.states.length === 0">none</span>
          </td>
          <td>
            {{note.price / 100}}
          </td>
          <td>
            {{note.ratingAvg}}
          </td>
          <td>
            {{note.viewCount}}
          </td>
          <td>
            <span class="notd__label" [ngClass]="{'label--red': note.state === 'TRASH', 'label--blue': note.state !== 'TRASH'}">{{ note.state }}</span>
          </td>
          <td>
            {{ note.created | date: 'MMM dd, yyyy' }}
          </td>
          <td>
            <input type="checkbox"
                   name="multi-select"
                   [checked]="existOnMultiList(note.id)"
                   (change)="multiSelect($event.target.checked, note)">
          </td>
          <td>
            <a [routerLink]="['/note', note.id]" class="notd__button--v2 button--black notd__m-r-5" title="Edit Note">Edit</a>
            <a
              *ngIf="note.state !== 'TRASH'"
              (click)="deleteSingleNote(note.id)"
              class="notd__button--v2 button--black"
              title="Delete Note">Delete</a>
            <div *ngIf="note.state === 'TRASH'" class="notd__display--inline-flex">
              <a
                (click)="restoreNoteModal(note.id)"
                class="notd__button--v2 button--black notd__m-r-5"
                title="Delete Note">Restore</a>
              <a
                (click)="deleteForeverModal(note.id)"
                class="notd__button--v2 button--black"
                title="Delete Note">Delete forever</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ngb-pagination 
      *ngIf="!loading && notesList.length"
      class="d-flex justify-content-center notd__m-t-40"
      [collectionSize]="notesTotal"
      [pageSize]="notesOnPage"
      [boundaryLinks]="true"
      [maxSize]="10"
      (pageChange)="paginationPageChanged($event)"
      [(page)]="offset" size="sm"></ngb-pagination>
  </section>
</div>



<ng-template #deleteNotesModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm removing selected notes.</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table notd-backoffice-table small">
      <thead class="thead-dark">
        <tr>
          <th>No.</th>
          <th>Note name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of multiSelectList; let i = index;">
          <td>{{i + 1}}</td>
          <td>{{note.title}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('delete')">Delete</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('close')">Close</button>
  </div>
</ng-template>

<ng-template #deleteSingleNoteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Note delete actions</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Please choose the type of action for deleting this note.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('trash')">Move to trush</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('delete')">Completely Remove</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('close')">Cancel</button>
  </div>
</ng-template>
