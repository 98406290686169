import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CircleProgressComponent} from './circle-progress-loader.component';

@NgModule({
  declarations: [
    CircleProgressComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    RouterModule
  ],
  exports: [ CircleProgressComponent ]
})

export class CircleProgressLoaderModule {}
