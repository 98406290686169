<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    Streams list <span class="searched-param" *ngIf="searchQuery">(result of: {{searchQuery}})</span>
    <fa-icon [icon]="['fas', 'list']"></fa-icon>
  </h3>
  <div class="search-for-streams">
    <form (ngSubmit)="submitStreamSearch($event)">
      <label for="search-stream">Search for stream</label>
      <input type="text" class="form-control" id="search-stream" #searchInput placeholder="Search for query...">
      <button type="submit" class="btn btn-primary mb-2">Search</button>
      <button
        (click)="clearSearch(searchInput)"
        *ngIf="searchQuery"
        class="btn btn-info ml-1">Clear</button>
      <button (click)="exportDataToCsv()" type="button" class="btn btn-warning" style="left: 20px; position: relative;">Export to CSV</button>
    </form>
  </div>
  <ng-container *ngIf="streamsList?.length > 0 && !this.loading">
    <table class="bo__table text--centered">
      <thead>
        <tr>
          <th class="label">
            <span>#</span>
          </th>
          <th>
            <column-sorter [text]="'Name'" [list]="streamsList" [field]="'name'"></column-sorter>
          </th>
          <th>Notd stream profile</th>
          <th>
            <column-sorter [text]="'Owner'" [list]="streamsList" [field]="'userName'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Posts'" [list]="streamsList" [field]="'numPosts'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Status'" [list]="streamsList" [field]="'kind'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Followers'" [list]="streamsList" [field]="'followers'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Members'" [list]="streamsList" [field]="'numMembers'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Subs'" [list]="streamsList" [field]="'numSubs'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Rating'" [list]="streamsList" [field]="'ratingAvg'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Purchases'" [list]="streamsList" [field]="'numPurchases'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Views'" [list]="streamsList" [field]="'numViews'"></column-sorter>
          </th>
          <th>
            <column-sorter [text]="'Created'" [list]="streamsList" [field]="'created'"></column-sorter>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let stream of streamsList; let i = index">
          <td>{{i+1+ ((streamPagination.offset-1) * streamPagination.pageSize)}}</td>
          <td class="text-left">
            <a [routerLink]="['/stream', stream.id]" class="link" title="{{stream.name}}">{{stream.name}}</a>
            <div class="statuses">
              <span class="status-tag" *ngIf="stream.featured && stream.featured === 1">Featured</span>
            </div>
          </td>
          <td class="text-center">
            <external-link-icon
              *ngIf="stream.externalLink"
              [title]="'View stream on notd'"
              [link]="stream.externalLink"></external-link-icon>
          </td>
          <td class="text-center">
            <fa-icon *ngIf="!stream.userName" [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
            <div *ngIf="stream.userName">
              <a [routerLink]="['/user', stream.userId]" title="{{stream.userName}}" class="link">{{stream.userName}}</a><br/>
              <a [routerLink]="['/user', stream.userId]" title="{{stream.userName}}" class="link small">{{stream.userEmail}}</a>
            </div>
          </td>
          <td class="text-center">{{stream['numPosts']}}</td>
          <td class="text-center">
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['Invalid']">Invalid</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['Default']">Default</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['OpenCommunity']">Open</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['InviteOnlyCommunity']">Invite</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['PrivateCommunity']">Private</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['Free']">Free</span>
            <span class="state-tag" *ngIf="stream['kind'] === streamKind['Last']">Last</span>
          </td>
          <td class="text-center">{{stream['followers']}}</td>
          <td class="text-center">{{stream['numMembers']}}</td>
          <td class="text-center">{{stream['numSubs']}}</td>
          <td class="text-center">{{stream['ratingAvg'] | number: '1.1-2'}}</td>
          <td class="text-center">{{stream['numPurchases']}}</td>
          <td class="text-center">{{stream['numViews']}}</td>
          <td>
            {{ stream.created | date: 'MMM dd, yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
    <ngb-pagination class="d-flex justify-content-center mt-10"
                    [collectionSize]="streamPagination.total"
                    [pageSize]="streamPagination.pageSize"
                    [boundaryLinks]="true"
                    [maxSize]="streamPagination.maxSize"
                    (pageChange)="pageChangedEvent($event)"
                    [(page)]="streamPagination.offset"></ngb-pagination>
  </ng-container>

  <ng-container *ngIf="streamsList.length === 0 && !this.loading">
    <div>
      There is no data in this criteria.
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <section class="bo__loading">
      <span class="bo__loading__spinner"></span>
      <p class="bo__loading__label">Loading streams list...</p>
    </section>
  </ng-container>
</div>
