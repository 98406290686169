import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {User} from '../../app/shared/types/user';
import {UserService} from '../../app/shared/services/user_service';

@Injectable()
export class UserResolve  {

constructor(@Inject(PLATFORM_ID) private platformId: any,
            private userService: UserService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
    if (isPlatformServer(this.platformId)) {
      return Promise.resolve(null);
    }
    return this.userService.getProfile(true).then((user) => {
        if (user && user.hasOwnProperty('admin') && user['admin'] === 1) {
            return user;
        } else {
            this.router.navigate(['/login']);
        }
      }, () => null)
      .catch(err => {
        console.log(err);
      });
  }
}
