export class ContentParagraph {
  quill: any;
  options: any;
  p: any;
  pButtonLabel = 'Set as paragraph';
  isMobile = false;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    this.injectParagraphEditor = this.injectParagraphEditor.bind(this);
    this.showParagraphEditor = this.showParagraphEditor.bind(this);
    this.hideParagraphEditor = this.hideParagraphEditor.bind(this);
    this.toggleParagraph = this.toggleParagraph.bind(this);
    this.updateParagraphButtonLabel = this.updateParagraphButtonLabel.bind(this);

    this.isMobile = this.quill.container.parentNode.classList.contains('is-mobile');

    this.quill.on('editor-change', () => {
      this.init();
    });

    this.quill.container.addEventListener('mouseleave', this.hideParagraphEditor);

    this.injectParagraphEditor();
  }

  init() {
    this.addEvents();
  }

  addEvents() {
    const quillContent = this.quill.root;
    const contentParagraphs = quillContent.querySelectorAll('p');


    for (const p of contentParagraphs) {
      p.addEventListener('mouseenter', this.showParagraphEditor);
    }
  }

  injectParagraphEditor() {
    const quillContainer = this.quill.root.parentNode;
    const imageEditorPanel = document.createElement('div');

    imageEditorPanel.className = 'paragraph-editor';
    imageEditorPanel.style.position = 'absolute';
    imageEditorPanel.style.display = 'none';

    const paragraphButton = document.createElement('a');

    paragraphButton.style.display = 'inline-block';
    paragraphButton.style.padding = '2px 3px';
    paragraphButton.style.backgroundColor = 'white';
    paragraphButton.style.border = '1px solid #ccccccba';
    paragraphButton.style.borderRadius = '5px';
    paragraphButton.style.cursor = 'pointer';
    paragraphButton.style.position = 'relative';
    paragraphButton.style.zIndex = '2';
    paragraphButton.innerText = this.pButtonLabel;
    paragraphButton.className = 'paragraph-toggle';
    paragraphButton.setAttribute('title', 'Set this as styled paragraph');

    paragraphButton.addEventListener('click', this.toggleParagraph);
    paragraphButton.addEventListener('mouseenter', () => {
      paragraphButton.style.backgroundColor = '#f5f5f5';
    });

    paragraphButton.addEventListener('mouseleave', () => {
      paragraphButton.style.backgroundColor = 'white';
    });

    imageEditorPanel.appendChild(paragraphButton);
    quillContainer.appendChild(imageEditorPanel);
  }

  showParagraphEditor(e: any) {
    const p = e.target;
    const paragraphEditor = this.quill.root.parentNode.querySelector('.paragraph-editor');
    const contentParagraphs = this.quill.root.querySelectorAll('p');

    paragraphEditor.style.display = 'none';

    for (const paragh of contentParagraphs) {
      paragh.classList.remove('p-hovered');
    }

    if (
      p.getElementsByTagName('img').length === 0 &&
      p.innerHTML.trim() !== '' &&
      p.innerHTML.trim() !== '<br>'
    ) {
      if (!this.checkForImageValidPosition(p)) {
        paragraphEditor.style.display = 'block';
        p.classList.add('p-hovered');
        this.updateParagraphButtonLabel(this.getParagraphProps(p).styled ? 'Remove paragraph' : 'Set as paragraph');
        this.setParagraphEditorPosition(p);
        this.p = p;
      }
    }
  }

  checkForImageValidPosition(p: any) {
    const quillContent = this.quill.root;
    const images = quillContent.querySelectorAll('img');
    const pRect = p.getBoundingClientRect();

    let hoverState = false;

    for (const img of images) {
      const imgRect = img.getBoundingClientRect();

      if (
        (pRect.top >= imgRect.top && pRect.top <= imgRect.bottom) ||
        (pRect.bottom >= imgRect.top && pRect.bottom <= imgRect.bottom)
      ) {
        hoverState = true;
        break;
      } else {
        hoverState = false;
      }
    }

    return hoverState;
  }

  hideParagraphEditor() {
    const paragraphEditor = this.quill.root.parentNode.querySelector('.paragraph-editor');
    const contentParagraphs = this.quill.root.querySelectorAll('p');

    paragraphEditor.style.display = 'none';

    for (const p of contentParagraphs) {
      p.classList.remove('p-hovered');
    }
  }

  getParagraphProps(sourceP: any) {
    if (sourceP) {
      const pRect = sourceP.getBoundingClientRect();
      const qEditorPos = this.quill.root.getBoundingClientRect();

      const imgPos = {
        top: (qEditorPos.top - pRect.top) < 0 ? (qEditorPos.top - pRect.top) * -1 : qEditorPos.top - pRect.top,
        left: (qEditorPos.left - pRect.left) < 0 ? (qEditorPos.left - pRect.left) * -1 : qEditorPos.left - pRect.left,
        right: (qEditorPos.right - pRect.right) < 0 ? (qEditorPos.right - pRect.right) * -1 : qEditorPos.right - pRect.right,
        bottom: (qEditorPos.bottom - pRect.bottom) < 0 ?
          (qEditorPos.bottom - pRect.bottom) * -1 : qEditorPos.bottom - pRect.bottom
      };

      return {
        sourceP,
        styled: sourceP.classList.contains('content-paragraph'),
        pos: imgPos
      };
    }
  }

  toggleParagraph() {
    if (this.p) {
      if (this.p.classList.contains('content-paragraph')) {
        this.p.classList.remove('content-paragraph');
      } else {
        this.p.classList.add('content-paragraph');
      }
      this.updateParagraphButtonLabel(this.getParagraphProps(this.p).styled ? 'Remove paragraph' : 'Set as paragraph');
      this.setParagraphEditorPosition(this.p);
    }
  }

  updateParagraphButtonLabel(value: string) {
    const paragraphEditor = this.quill.root.parentNode.querySelector('.paragraph-toggle');
    if (paragraphEditor) {
      paragraphEditor.innerText = value;
    }
  }

  setParagraphEditorPosition(selectedP: any) {
    const editPanel = this.quill.root.parentNode.querySelector('.paragraph-editor');
    const pProps = this.getParagraphProps(selectedP);

    editPanel.style.display = 'block';
    editPanel.style.top = `${pProps.pos.top - 20}px`;
    editPanel.style.right = `${pProps.pos.right - 10}px`;
  }

  disableQuillPanel() {
    const quillToolbar = this.quill.container.parentNode.querySelector('.ql-toolbar');
    const toolbarButtons = quillToolbar.querySelectorAll('.ql-formats');

    for (const button of toolbarButtons) {
      button.style.opacity = '.3';
      button.style.pointerEvents = 'none';
    }
  }

  enableQuillPanel() {
    const quillToolbar = this.quill.container.parentNode.querySelector('.ql-toolbar');
    const toolbarButtons = quillToolbar.querySelectorAll('.ql-formats');

    for (const button of toolbarButtons) {
      button.style.opacity = '1';
      button.style.pointerEvents = 'auto';
    }
  }
}
