import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';

const heroesRoutes: Routes = [
  { path: 'dashboard',  component: DashboardComponent },
  { path: 'streams',  component: DashboardComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(heroesRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }
