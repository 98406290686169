import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

export interface CircleProgressOptionsInterface {
  class?: string;
  percent?: number;
  imageSrc?: string;
}

export class CircleProgressOptions implements CircleProgressOptionsInterface {
  class = '';
  percent = 0;
  imageSrc = undefined;
}

@Component({
  selector: 'circle-progress-loader',
  templateUrl: './circle-progress-loader.component.html',
  styleUrls: ['./circle-progress-loader.component.scss']
})
export class CircleProgressComponent implements OnChanges, OnInit {
  @Input() class: string;
  @Input() progress: number;
  @Input() imageSrc: string;
  @Input() imageHeight: number;
  @Input() imageWidth: number;

  @Input() show = false;

  stroke = 5;
  radius = 40;
  circumference: any;
  dashoffset: number;

  constructor() {
    this.circumference = 2 * Math.PI * this.radius;
  }

  ngOnInit() {
    this.setProgress(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress.currentValue !== changes.progress.previousValue) {
      this.setProgress(changes.progress.currentValue);
    }
  }

  private setProgress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }
}
