import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginRedirectGuard} from './quards/login-redirect.guard';
import {UserResolve} from './resolvers/user-resolve.service';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'users/:searchString',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'user/:id',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'user/:id/:transaction',
    loadChildren: () => import('./user/transaction/user-transaction.module').then(m => m.UserTransactionModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'notes/:searchString',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'note/:id',
    loadChildren: () => import('./notes/note/note.module').then(m => m.NoteModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'notes-flagged',
    loadChildren: () => import('./notes/flagged/flagged.module').then(m => m.FlaggedModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'featured-notes',
    loadChildren: () => import('./notes/featured/featured.module').then(m => m.FeaturedNotesModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  // {
  //   path: 'trashed-notes',
  //   loadChildren: () => import('./notes/trashed/trash.module').then(m => m.TrashedNotesModule),
  //   resolve: {
  //     user: UserResolve
  //   },
  //   canActivate: [LoginRedirectGuard]
  // },
  {
    path: 'payments',
    canActivate: [LoginRedirectGuard],
    resolve: {
      user: UserResolve
    },
    children: [
      {
        path: 'latest',
        loadChildren: () => import('./payments/latest/latest.module').then(m => m.LatestPaymentModule),
      }
    ]
  },
  {
    path: 'users-payouts',
    loadChildren: () => import('./users/payouts/payouts.module').then(m => m.PayoutsModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'streams',
    loadChildren: () => import('./streams/streams.module').then(m => m.StreamsModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'featured-streams',
    loadChildren: () => import('./streams/featured/featured.module').then(m => m.FeaturedStreamsModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'streams/:searchString',
    loadChildren: () => import('./streams/streams.module').then(m => m.StreamsModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'stream/:id',
    loadChildren: () => import('./streams/stream/stream.module').then(m => m.StreamModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'stream/:id/sales',
    loadChildren: () => import('./streams/sales/sales.module').then(m => m.SalesModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'flagged-comments',
    loadChildren: () => import('./comments/flagged/flagged-comments.module').then(m => m.FlaggedCommentsModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    pathMatch: 'full',
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class AppRoutingModule { }
