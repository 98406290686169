<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    List of payouts
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
  </h3>
  <div class="search-for-note">
    <form>
      <label>Select status:</label>
      <select (change)="changeStatus($event)">
        <option *ngFor="let payout of payoutsTypes; let i = index" [selected]="i === selectedStatusType" [value]="payout.val">{{payout.label}}</option>
      </select>
    </form>
  </div>
  <ng-container *ngIf="payoutsList?.length > 0">
    <table class="table notd-backoffice-table small">
      <thead class="thead-dark">
        <tr>
          <th scope="col" class="align-middle">#</th>
          <th scope="col" class="align-middle">User</th>
          <th scope="col" class="align-middle">User Address</th>
          <th scope="col" class="align-middle">Bank info</th>
          <th scope="col" class="align-middle text-center">Destination</th>
          <th scope="col" class="align-middle text-center">Amounts<br /><span class="small">(req / sent)</span></th>
          <th scope="col" class="align-middle text-center">Edit</th>
          <th scope="col" class="align-middle text-center">Docs</th>
          <th scope="col" class="align-middle text-center">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payout of payoutsList; let i = index">
          <td>{{i+1}}</td>
          <td>
            <fa-icon *ngIf="!payout?.user" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <a *ngIf="payout?.user" [routerLink]="['/user', payout.user?.id]" class="link" title="{{payout.user?.displayName}}">{{payout.user?.displayName}}</a>
          </td>
          <td class="small">
            <span class="line">{{payout.user?.address?.city}}</span>
            <span class="line">{{payout.user?.address?.countryName}} {{payout.user?.address?.postalCode}}</span>
            <span class="line" *ngIf="payout.user?.address?.region">{{payout.user?.address?.region}}</span>
          </td>
          <td class="small">
            <span class="line"><strong>bank name:</strong> {{payout.bank.name}}</span>
            <span class="line"><strong>iban:</strong> {{payout.bank.iban || 'none'}}</span>
            <span class="line"><strong>swift:</strong> {{payout.bank.swift || 'none'}}</span>
            <span class="line"><strong>account holder:</strong> {{payout.bank.accountHolder}}</span>
            <span class="line"><strong>account number:</strong> {{payout.bank.accountNumber}}</span>
            <span class="line" *ngIf="payout.bank.routingNumber">
              <strong>routing number:</strong>
              {{payout.bank.routingNumber}}
            </span>
            <span class="line"><strong>transfer method:</strong> {{payout.bank.transferMethod}}</span>
          </td>
          <td class="text-center">{{payoutDestinationType[payout.destType]}}</td>
          <td class="text-center">
            {{payout.amountReq / 100}} / {{payout.amountSent / 100}}
          </td>
          <td class="text-center">
            <a (click)="openEditStatus(payout)">
              <fa-icon [icon]="['fas', 'pen']"></fa-icon>
            </a>
          </td>
          <td class="text-center">
            <a (click)="openDocFile(payout, 'tax')" class="link strong" title="Check tax file">Tax</a> / <a (click)="openDocFile(payout, 'id')" class="link strong" title="Check ID file">ID</a>
          </td>
          <td class="text-center">{{payout.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="payoutsList?.length === 0">
    <div>
      There is no data in this criteria.
    </div>
  </ng-container>
</div>

<ng-template #changeStatusModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change payments status</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-label">
      <label>Amount requested:</label>
      <span class="text">{{payoutDetailsModal['amountReq'] / 100}}</span>
    </div>
    <div class="text-label">
      <label>Amount Sent:</label>
      <span class="text">{{payoutDetailsModal['amountSent'] / 100}}</span>
    </div>
    <div class="text-label">
      <label>Destination type:</label>
      <span class="text">{{payoutDestinationType[payoutDetailsModal['destType']]}}</span>
    </div>
    <div class="text-label">
      <label>Status:</label>
      <span class="text">{{payoutStatus[payoutDetailsModal['status']]}}</span>
    </div>
    <div class="text-label">
      <label>Request Ip address:</label>
      <span class="text">{{payoutDetailsModal['reqIpAddress']}}</span>
    </div>
    <div class="text-label">
      <label>Created:</label>
      <span class="text">{{payoutDetailsModal['created'] | date: 'dd/MM/yyyy'}}</span>
    </div>
    <div class="text-label">
      <label>Select new status:</label>
      <select (change)="changeStatus($event, 'modal')">
        <option *ngFor="let payout of payoutsTypes; let i = index" [selected]="i === payoutDetailsModal['status']" [value]="payout.val">{{payout.label}}</option>
      </select>
    </div>
    <div class="text-label nbb">
      <label>Staff note:</label>
      <textarea [(ngModel)]="modalStaffNote"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('save')">Save</button>
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('close')">Close</button>
  </div>
</ng-template>
