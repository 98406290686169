import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {DashboardComponent} from './dashboard.component';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {SideMenuModule} from '../side-menu/side-menu.module';

import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faSearch, faStickyNote, faSync, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {faIdCard} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: DashboardComponent }]),
    CommonModule,
    FormsModule,
    DashboardRoutingModule,
    SideMenuModule,
    FontAwesomeModule
  ],
  declarations: [
    DashboardComponent
  ],
  providers: []
})
export class DashboardModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faIdCard, faSearch, faStickyNote, faUserCircle);
  }
}
