/* eslint-disable @typescript-eslint/naming-convention */
import * as quill from 'quill';

const QuillObject: any = quill;
const Parchment = QuillObject.import('parchment');
const BaseImageFormat = QuillObject.import('formats/image');
const Block = QuillObject.import('blots/block');

// eslint-disable-next-line @typescript-eslint/naming-convention
const ImageFormatAttributesList = [
  'alt',
  'height',
  'display',
  'width',
  'class',
  'style',
  'background',
  'data-link',
  'data-caption',
  'data-caption-id',
  'data-caption-pos'
];

export class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

class ParagraphBlot extends Block {
  static create(value) {
    const node = super.create();
    if (value.class !== '') {
      node.setAttribute('class', value.class);
    }
    if (value.id !== '') {
      node.setAttribute('id', value.id);
    }
    return node;
  }
  static formats(node) {
    if (node) {
      return {
        class: node.getAttribute('class') ? node.getAttribute('class') : '',
        id: node.getAttribute('id') ? node.getAttribute('id') : '',
      };
    }
  }
}

ParagraphBlot.blotName = 'p';
ParagraphBlot.tagName = 'p';
QuillObject.register(ParagraphBlot);

class DivBlot extends Block {
}

DivBlot.tagName = 'div';
DivBlot.blotName = 'div';
DivBlot.allowedChildren = Block.allowedChildren;
DivBlot.allowedChildren.push(Block);

QuillObject.register(DivBlot);
