
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {SearchService} from '../../app/shared/services/search_service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'notd-boffice-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  search: any = {
    users: '',
    streams: '',
    notes: ''
  };

  numUsers = 0;
  numStreams = 0;
  numNotes = 0;
  dbUsers = [];
  dbStreams = [];
  dbNotes = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.getNotedUsers();
    this.getNotdNotes();
    this.getNotdStreams();
  }

  searchFor(type: string) {
    this.router.navigate([type, this.search[type]]);
  }

  getNotedUsers() {
    this.adminService.getUsers({}).then((res: any) => {
      this.numUsers = res.total;
      this.dbUsers = res.users;
    });
  }

  getNotdNotes() {
    this.adminService.getNotes({}).then((res: any) => {
      this.numNotes = res.total;
      this.dbNotes = res.posts;
    });
  }

  getNotdStreams() {
    this.adminService.getStreams({}).then((res: any) => {
      this.numStreams = res.total;
      this.dbStreams = res.channels;
    });
  }
}
