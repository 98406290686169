import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SideMenuModule} from '../../side-menu/side-menu.module';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

import {SalesComponent} from './sales.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCalendar, faList, faSync} from '@fortawesome/free-solid-svg-icons';
import {faMoneyBillAlt} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [ SalesComponent ],
  imports: [
    RouterModule.forChild([{ path: '', component: SalesComponent }]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    NgbDatepickerModule,
    FontAwesomeModule
  ],
  exports: [ SalesComponent ]
})

export class SalesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faList, faMoneyBillAlt, faCalendar);
  }
}
