import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SideMenuModule} from '../../side-menu/side-menu.module';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

import {UserTransactionComponent} from './user-transaction.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCalendar, faMoneyBillAlt, faSync, faUsers} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [ UserTransactionComponent ],
  imports: [
    RouterModule.forChild([{ path: '', component: UserTransactionComponent }]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    NgbDatepickerModule,
    FontAwesomeModule
  ],
  exports: [ UserTransactionComponent ]
})

export class UserTransactionModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faUsers, faMoneyBillAlt, faCalendar);
  }
}
