export class QuillEditorConfiguration {

  private contentToolbar = {
    toolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],        // toggled buttons
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
      [{ direction: 'rtl' }],                         // text direction

      [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ align: [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'ann', 'anchor', 'pin']                   // link and image, video, annotation
    ],
    clipboard: {
      allowed: {
          tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span', 'img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          attributes: ['href', 'rel', 'target', 'alt', 'title', 'src']
      },
      keepSelection: true,
      substituteBlockElements: true,
      magicPasteLinks: true,
      hooks: {
          uponSanitizeElement(node) {
            // console.log(node);
          },
      },
    }
  };

  private modules = {
    toolbar: '#toolbar-container'
  };

  private scrollingContainer = {
    scrollingContainer: '#scrolling-container'
  };

  getConfig() {
    return this.contentToolbar;
  }

  getModules() {
    return this.modules;
  }

  getScrollingContainer() {
    return this.scrollingContainer;
  }
}
