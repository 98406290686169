import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

import {NoteService} from '../services/note_service';

@Directive({
    selector: '[annotations]'
})
export class AnnotationDirective implements OnInit, OnChanges {
    @Input() note: any;
    @Input() allowAnnotations: boolean;
    @Input() containerSelector: string;
    @Output() statusChange = new EventEmitter<boolean>();
    @Output() errorMessage = new EventEmitter<any>();
    annotationCloud: any;
    cloudPosition: any = {};
    selectedText;
    selectedAnnotationCommentText;
    range: any;
    textAfter: any;
    annotationText = '';
    annotationCounter = 0;
    targetEvent: any;
    currentContentCopy = '';
    annotations: any[] = [];
    annContainer: any;
    annotationNode: any;
    notAllowedStyles = ['strong>', 'i>', 'span>', 'em>', 'u>'];
    elementOnMouseDown: any;
    quillEditorInit = false;

    private contentSelector = 'main-note-view__content__current__wrapper';

    constructor(
        private renderer: Renderer2,
        private noteService: NoteService,
        private el: ElementRef,
         // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private platformId: Object) {

        }

    @HostListener('mousedown', ['$event'])
    mouseDown(event: Event) {
      this.elementOnMouseDown = event.target;
    }

    @HostListener('mouseup', ['$event'])
    selection(event: Event) {
      if (!this.containerSelector || this.containerSelector !== 'ql-editor') {
        this.annotationTargetingAndShowing(event);
      }
    }

    @HostListener('click', ['$event'])
    buttonClickTrigger(event: Event) {
      if (event.target['classList'].contains('ql-ann')) {
        this.annotationTargetingAndShowing(false, 'ann-button');
      }
    }

    public ngOnInit(): void {
      const events = ['quill-editor-init-event', 'paid-content-loaded'];

      if (isPlatformServer(this.platformId)) {
        return;
      }
      for (const ev of events) {
        window.addEventListener(ev, e => {
          if (!this.quillEditorInit) {
            this.quillEditorInit = true;
            this.initAnnotations();
          }
        });
      }
    }

    public initAnnotations(): void {
      if (this.containerSelector) {
        this.annContainer = this.el.nativeElement.querySelector(`.${this.containerSelector}`);
      } else {
          this.annContainer = this.el.nativeElement;
      }

      this.makeContentCopy();
      this.renderer.setStyle(this.annContainer, 'position', 'relative');
      if (this.note && this.note.id) {
        this.getAnnotations(this.note.id);
      }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.note && changes.note.currentValue) {
            // this.getAnnotations(this.note.id);
        }
    }

    annotationTargetingAndShowing(event?: Event | any, action?: string) {
      if (
        (event && (
          event.target['parentNode'].classList.contains(this.containerSelector ? this.containerSelector : this.contentSelector) ||
          (
            event.target['parentNode']['parentNode']
              .classList.contains(this.containerSelector ? this.containerSelector : this.contentSelector) &&
            (
              event.target['tagName'].toLowerCase() === 'li' ||
              event.target['tagName'].toLowerCase() === 'strong' ||
              event.target['tagName'].toLowerCase() === 'u' ||
              event.target['tagName'].toLowerCase() === 'em'
            )
          )
        ) && this.allowAnnotations) ||
        !event && this.allowAnnotations && action === 'ann-button') {
        let selectedText;

        if (this.elementOnMouseDown && !this.elementOnMouseDown.classList.contains('ann-field')) {
          this.hideCloud();
        }

        if (isPlatformBrowser(this.platformId) && window && window.getSelection) {
          selectedText = window.getSelection();
        } else if (document.getSelection) {
          selectedText = document.getSelection();
        }

        const textOffsets = {
          start: (selectedText.focusOffset > selectedText.anchorOffset) ? selectedText.focusOffset : selectedText.anchorOffset,
          finish: (selectedText.focusOffset > selectedText.anchorOffset) ? selectedText.anchorOffset : selectedText.focusOffset,
        };

        if (textOffsets.start - textOffsets.finish > 0) {
          this.range = selectedText.getRangeAt(0);
          const rect = this.range.getBoundingClientRect();
          this.selectedText = selectedText.toString();
          this.textAfter = window.getSelection()
            .anchorNode['textContent']
            .substring(
              textOffsets.start,
              textOffsets.start + 20
            );

          this.targetEvent = (event) ? event.target : document.querySelector('.ql-editor');

          if (
            this.getSelectionHtml().indexOf(this.notAllowedStyles[0]) > 0 ||
            this.getSelectionHtml().indexOf(this.notAllowedStyles[1]) > 0 ||
            this.getSelectionHtml().indexOf(this.notAllowedStyles[2]) > 0 ||
            this.getSelectionHtml().indexOf(this.notAllowedStyles[3]) > 0 ||
            this.getSelectionHtml().indexOf(this.notAllowedStyles[4]) > 0
          ) {
            this.errorMessage.emit({
              title: 'Annotation creator message',
              message: 'You are not able to add the annotation in this place.'
            });
          } else {
            if (this.elementOnMouseDown && !this.elementOnMouseDown.classList.contains('ann-field')) {
              this.createAnnotationCloud();
              this.setCloudPosition(rect);
            }
          }
        } else {
          this.hideCloud();
        }
      }
    }

    createAnnotationCloud() {
        const cloudHtml = `<div contenteditable="true" class="text-area ann-field"></div>` +
          `<div class="link-add-wrapper">` +
            `<textarea class="url-text-area" placeholder="Copy your link here"></textarea>` +
            `<button class="btn add-link">Add</button> ` +
          `</div>` +
          `<div class="buttons">` +
            `<button class="btn btn-sm save">Save</button>` +
            `<button class="btn btn-sm cancel">Cancel</button>` +
            `<button class="btn btn-sm link" disabled>Link</button>` +
            `<button class="btn btn-sm close-an">Close</button>` +
          `</div>`;

        this.annotationCloud = document.createElement('div');
        this.annotationCloud.className = 'annotation-cloud';
        this.annotationCloud.innerHTML = cloudHtml;

        this.renderer.appendChild(this.containerSelector === 'ql-editor' ?
            this.annContainer.parentNode : this.annContainer, this.annotationCloud);

        this.renderer.setStyle(this.annotationCloud, 'display', 'block');

        if (this.containerSelector !== 'ql-editor') {
          this.statusChange.emit(true);
        }

        this.annotationCloud.querySelector('.save').addEventListener('click', this.setAnnotation.bind(this), false);
        this.annotationCloud.querySelector('.cancel').addEventListener('click', this.hideCloud.bind(this));
        this.annotationCloud.querySelector('.link').addEventListener('click', this.addLink.bind(this));
        this.annotationCloud.querySelector('.close-an').addEventListener('click', this.hideCloud.bind(this));
        this.annotationCloud.querySelector('.ann-field').addEventListener('keyup', this.setAnnotationCloudText.bind(this));
        this.annotationCloud.querySelector('.ann-field').addEventListener('paste', this.setAnnotationTextOnPaste.bind(this));
        this.annotationCloud.querySelector('.ann-field').addEventListener('click', this.setAnnotationText.bind(this));
        this.annotationCloud.querySelector(`.btn.add-link`)
          .addEventListener('click', this.injectLinkToText.bind(this), false);
    }

    makeContentCopy() {
        this.currentContentCopy = this.annContainer.innerHTML;
    }

    hideCloud() {
        if (this.annotationCloud) {
          const clouds = document.querySelectorAll('.annotation-cloud');

          Array.from(clouds).forEach(cloud => {
            this.renderer.removeChild(this.containerSelector === 'ql-editor' ?
              this.annContainer.parentNode : this.annContainer, cloud);
          });

          if (this.containerSelector !== 'ql-editor') {
              this.statusChange.emit(false);
          }

          this.annotationCloud = null;
          this.selectedText = '';
          this.range = '';
          this.textAfter = '';
        }
    }

    setCloudPosition(selectionRect: any) {
        const rootRect = this.annContainer.getBoundingClientRect();
        Object.assign(this.cloudPosition, {
            left: selectionRect.left - rootRect.left - 125 + selectionRect.width / 2,
            top: selectionRect.top - rootRect.top + 38
        });

        this.annotationCloud.setAttribute('style', `left: ${this.cloudPosition.left}px; top: ${this.cloudPosition.top}px`);
    }

    getAnnotations(noteId: string) {
        this.noteService.getAnnotations(noteId).then(annotations => {
          for (const annotation of Object.values(annotations)) {
            if (annotation['id'] && annotation['id'] !== 'undefined' &&
              annotation['startWord'] && annotation['startWord'] !== 'undefined' &&
              annotation['endWord'] && annotation['endWord'] !== 'undefined') {
              this.annotations.push(annotation);
              this.putAnnotationInContent(annotation);
            }
          }
          this.checkAnnotationCloudPosition();
        });
    }

    setAnnotation() {
        const annText = this.annotationCloud.querySelector('.text-area') ?
          this.annotationCloud.querySelector('.text-area').innerHTML : false;

        if (annText) {
          const point =
            `<em data-id="123456789000" ` +
            `data-text="" ` +
            `class="ann-point">` +
              `<em class="no"></em>` +
            `</em>`;

          const contentText = this.annContainer.innerHTML;

          let lastMatch;
          const contentHits = [];

          if ((lastMatch = contentText.indexOf(this.selectedText)) >= 0) {
            contentHits.push(lastMatch);
            while ((lastMatch = contentText.indexOf(this.selectedText, lastMatch + this.selectedText.length)) >= 0) {
                contentHits.push(lastMatch);
            }
          }

          const textBreakers = [];

          contentHits.map(breaker => {
            if (contentText
                  .substring(breaker + this.selectedText.length, breaker + this.selectedText.length + this.textAfter.length)
                  .indexOf(this.textAfter) >= 0) {
              textBreakers.push(breaker);
            }
          });

          if (textBreakers.length > 1) {
            this.hideCloud();
            this.errorMessage.emit({
              title: 'Annotation error',
              message: 'Please try to add more specific location for this annotation. This place is similar ' +
              'to other one in the text.'
            });
          } else {
            const annData = Object.assign({}, {
              text: annText,
              startWord: this.selectedText,
              endWord: this.textAfter,
              startPos: textBreakers[0],
              endPos: textBreakers[0] + this.selectedText.length
            });

            this.noteService.putAnnotations(this.note.id, annData).then((res) => {
              this.putAnnotationInContent(res);
              this.checkAnnotationCloudPosition();
              setTimeout(() => {
                this.hideCloud();
              }, 0);
            }).catch(err => {
              this.errorMessage.emit({
                title: 'Annotation error',
                message: (err.error && err.error.message) ? err.error.message : 'Error while saving annotation. Try once again.'
              });
              this.hideCloud();
            });
          }
        }
    }

    putAnnotationInContent(annotationDetails: any) {
        const point =
            `<em data-id="${annotationDetails.id}" ` +
                `data-text='${annotationDetails.text}' ` +
                `class="ann-point">` +
                `<em class="no">${this.annotationCounter}</em>` +
            `</em>`;

        const contentText = this.annContainer.innerHTML;
        const endWords = (annotationDetails.endWord && annotationDetails.endWord.length > 0) ?
          annotationDetails.endWord.substring(0, 10) : '';
        const contentType = point;

        let lastMatch;
        const contentHits = [];

        if ((lastMatch = contentText.indexOf(annotationDetails.startWord)) >= 0) {
            contentHits.push(lastMatch);
            while ((lastMatch = contentText.indexOf(annotationDetails.startWord, lastMatch + annotationDetails.startWord.length)) >= 0) {
                contentHits.push(lastMatch);
            }
        }

        const textBreakers = [];

        contentHits.map(breaker => {
          if (contentText
            .substring(
              breaker + annotationDetails.startWord.length, breaker + annotationDetails.startWord.length + annotationDetails.endWord.length)
            .indexOf(annotationDetails.endWord) >= 0) {
            textBreakers.push(breaker);
          }
        });

        if (textBreakers.length === 1) {
          this.annContainer.innerHTML = contentText.substr(0, textBreakers[0] + annotationDetails.startWord.length) +
            contentType + contentText.substr(textBreakers[0] + annotationDetails.startWord.length);
        }

        this.hideCloud();
        this.reorderNumberLabels();

        const annotationsPoints = this.annContainer.querySelectorAll('.ann-point');

        Array.from(annotationsPoints).forEach((annPoint: any) => {
          // annPoint.removeEventListener('mouseenter', () => {});
          annPoint.addEventListener('mouseenter', (e) => {
            this.removeAnnotationTooltip();
          });
        });
    }

    editModeAnnPoint(e: Event) {
        e.preventDefault();
        this.annotationNode.classList.add('edit-mode');
    }

    cancelAnnPoint(e: Event) {
        e.preventDefault();
        this.annotationNode.classList.remove('edit-mode');
    }

    deleteAnnPoint(e: Event) {
        e.preventDefault();
        const el = document.querySelector(`.ann-point[data-id="${e.target['dataset']['id']}"]`);

        if (el) {
            this.noteService.deleteAnnotations(this.note.id, e.target['dataset']['id']).then(() => {
                this.renderer
                    .removeChild(this.annContainer, document.querySelector(`.ann-point[data-id="${e.target['dataset']['id']}"]`));
            });
        }
        this.removeAnnotationTooltip();
    }

    reorderNumberLabels() {
      setTimeout(() => {
        this.annotationCounter = 0;
        const elements = this.annContainer.querySelectorAll('.ann-point .no');

        Array.from(elements).forEach((point: any) => {
            this.annotationCounter = this.annotationCounter + 1;
            point.innerText = this.annotationCounter;
        });
      }, 50);
    }

    updateAnnotationText(event: Event) {
      const annotationId = this.annotationNode['dataset']['id'];
      const annotationText = this.annotationNode.querySelector('.text-area') ?
        this.annotationNode.querySelector('.text-area').innerHTML : false;

      this.cancelAnnPoint(event);
      this.noteService.getAnnotations(this.note.id).then(annotations => {
        for (const ann of Object.values(annotations)) {
          if (ann['id'] === annotationId) {
            const annotationData = Object.assign({}, {
              id: event.target['dataset']['id'],
              text: annotationText,
              startWord: ann['startWord'],
              endWord: ann['endWord']
            });

            this.noteService.updateAnnotations(this.note.id, annotationData).then(() => {
              this.annContainer.querySelector(`.ann-point[data-id="${annotationId}"]`).setAttribute('data-text', annotationText);
              if (this.annContainer.querySelector('.text')) {
                this.annContainer.querySelector('.text').innerHTML = annotationText;
              }

              if (this.annotationNode) {
                this.annotationNode.querySelector('.text').innerHTML = annotationText;
              }
            });
          }
        }
      });
    }

    setAnnotationText(event: Event) {
        this.checkForSelectedAnchor();
    }

    setAnnotationCloudText(event: any) {
      this.annotationText = event.target['innerHTML'];
    }

    setAnnotationTextOnPaste(e: any) {
      let text = '';
      e.preventDefault();
      text = (e.originalEvent || e).clipboardData.getData('text/plain');
      e.clipboardData.setData('text/plain', '');
      window.document.execCommand('insertHTML', false, text.replace('/\x0D/g', '\\n'));
    }

    checkForSelectedAnchor() {
      let selectedText;

      if (window.getSelection) {
        selectedText = window.getSelection();
      } else if (document.getSelection) {
        selectedText = document.getSelection();
      }

      const textOffsets = {
        start: (selectedText.focusOffset > selectedText.anchorOffset) ? selectedText.focusOffset : selectedText.anchorOffset,
        finish: (selectedText.focusOffset > selectedText.anchorOffset) ? selectedText.anchorOffset : selectedText.focusOffset,
      };

      if (textOffsets.start - textOffsets.finish > 0) {
        const range = selectedText.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        selectedText = selectedText.toString();
        const textAfter = window.getSelection()
          .anchorNode['textContent']
          .substring(
            textOffsets.start,
            textOffsets.start + 20
          );

        if (
          this.getSelectionHtml().indexOf(this.notAllowedStyles[0]) > 0 ||
          this.getSelectionHtml().indexOf(this.notAllowedStyles[1]) > 0 ||
          this.getSelectionHtml().indexOf(this.notAllowedStyles[2]) > 0 ||
          this.getSelectionHtml().indexOf(this.notAllowedStyles[3]) > 0 ||
          this.getSelectionHtml().indexOf(this.notAllowedStyles[4]) > 0
        ) {
          this.errorMessage.emit({
            title: 'Annotation creator message',
            message: 'You are not able to add link in here. Try in different place.'
          });
        } else {
          if (selectedText && selectedText.length > 0) {
            this.selectedAnnotationCommentText = {
              selected: selectedText,
              textAfter
            };

            this.setLinkButtonEnable();
          } else {
            this.setLinkButtonDisable();
          }
        }
      } else {
        this.setLinkButtonDisable();
      }
    }

    setLinkButtonEnable() {
      if (this.annotationNode) {
        this.annotationNode.querySelector('.link').disabled = false;
      }
      if (this.annotationCloud) {
        this.annotationCloud.querySelector('.link').disabled = false;
      }
    }

    setLinkButtonDisable() {
      if (this.annotationNode) {
        this.annotationNode.querySelector('.link').disabled = true;
      }
      if (this.annotationCloud) {
        this.annotationCloud.querySelector('.link').disabled = true;
      }
    }

    addLink() {
      let linkWrapper: any;
      if (this.annotationNode) {
        linkWrapper = this.annotationNode.querySelector('.link-add-wrapper');
      }
      if (this.annotationCloud) {
        linkWrapper = this.annotationCloud.querySelector('.link-add-wrapper');
      }
      if (linkWrapper && linkWrapper.classList.contains('show')) {
        linkWrapper.classList.remove('show');
      } else {
        linkWrapper.classList.add('show');
      }
    }

    closeLinkFeatures() {
      if (this.annotationNode) {
        this.annotationNode.querySelector('.link-add-wrapper').classList.remove('show');
      }

      if (this.annotationCloud) {
        this.annotationCloud.querySelector('.link-add-wrapper').classList.remove('show');
      }
    }

    injectLinkToText() {
      let linkUrl: any;
      let contentText: any;

      if (this.annotationNode) {
        linkUrl = this.annotationNode.querySelector('.url-text-area').value;
        contentText = this.annotationNode.querySelector('.text-area').innerHTML;
      }

      if (this.annotationCloud) {
        linkUrl = this.annotationCloud.querySelector('.url-text-area').value;
        contentText = this.annotationCloud.querySelector('.text-area').innerHTML;
      }

      let lastMatch;
      const contentHits = [];

      if ((lastMatch = contentText.indexOf(this.selectedAnnotationCommentText.selected)) >= 0) {
        contentHits.push(lastMatch);
        while (
          (lastMatch = contentText.indexOf(this.selectedAnnotationCommentText.selected,
          lastMatch + this.selectedAnnotationCommentText.selected.length)) >= 0) {
          contentHits.push(lastMatch);
        }
      }

      const textBreakers = [];

      contentHits.map(breaker => {
        if (contentText
          .substring(
            breaker + this.selectedAnnotationCommentText.selected.length,
            breaker + this.selectedAnnotationCommentText.selected.length +
            this.selectedAnnotationCommentText.textAfter.length)
          .indexOf(this.selectedAnnotationCommentText.textAfter) >= 0) {
          textBreakers.push(breaker);
        }
      });

      if (textBreakers.length === 1) {
        const textToReplace = contentText.substring(
          textBreakers[0],
          textBreakers[0] + this.selectedAnnotationCommentText.selected.length);

        const newContentText = contentText.replace(textToReplace,
          `<a href="${linkUrl}" target="_blank" title="${textToReplace}">${textToReplace}</a>`);


        if (this.annotationNode) {
          this.annotationNode.querySelector('.text-area').innerHTML = newContentText;
        }

        if (this.annotationCloud) {
          this.annotationCloud.querySelector('.text-area').innerHTML = newContentText;
        }

        this.closeLinkFeatures();
        this.setLinkButtonDisable();
      } else if (textBreakers.length > 1) {
        this.errorMessage.emit({
          title: 'Annotation creator message',
          message: 'You are not able to add link in here. Place it in different place.'
        });
      }
    }

    checkAnnotationCloudPosition() {
        const annoPoint = document.querySelectorAll('.ann-point');

        Array.from(annoPoint).forEach(point => {
            point.addEventListener('mouseenter', () => {
                const containerCords = this.annContainer.getBoundingClientRect();
                const pointCords = point.getBoundingClientRect();
                let position: string;

                if (pointCords.left - 120 < containerCords.left) {
                    position = 'left';
                }

                if (pointCords.right + 120 > containerCords.right) {
                    position = 'right';
                }
                this.showAnnotationTooltip(point, pointCords, position);
            }, false);
        });
    }

    showAnnotationTooltip(pointEl: any, pointCords: any, pos?: string) {
      const annDetails = {
        id: pointEl['dataset']['id'],
        text: pointEl['dataset']['text']
      };

      const publicAnnotation = `<span class="text">${annDetails.text}</span>`;

      const adminAnnotation =
        `<i class="text">${annDetails.text}</i>` +
        `<div contenteditable="true" class="text-area edit-text">${annDetails.text}</div>` +
        `<div class="link-add-wrapper">` +
          `<textarea class="url-text-area" placeholder="Copy your link here"></textarea>` +
          `<button class="btn add-link" data-id="${annDetails.id}">Add</button> ` +
        `</div>` +
        `<div class="buttons">` +
          `<button class="btn btn-sm edit" data-id="${annDetails.id}">Edit</button> ` +
          `<button class="btn btn-sm save" data-id="${annDetails.id}">Save</button> ` +
          `<button class="btn btn-sm delete" data-id="${annDetails.id}">Delete</button> ` +
          `<button class="btn btn-sm cancel" data-id="${annDetails.id}">Cancel</button>` +
          `<button class="btn btn-sm link" disabled data-id="${annDetails.id}">Link</button>` +
          `<button class="btn btn-sm close-an" data-id="${annDetails.id}">Close</button>` +
        `</div>`;

      this.annotationNode = document.createElement('div');
      this.annotationNode.classList.add('annotation-tooltip');
      if (pos) {
        this.annotationNode.classList.add(pos);
      }
      this.annotationNode.setAttribute('data-id', annDetails.id);
      this.annotationNode.setAttribute('style', `left: ${pointCords['left']}px; top: ${pointCords['top']}px`);

      const rootRect = this.annContainer.getBoundingClientRect();
      const position = {
        left: pointCords.left - rootRect.left - 110 + pointCords.width / 2,
        top: pointCords.top - rootRect.top + 38
      };

      if (pos === 'left') {
        position.left = position.left + 80;
      }

      if (pos === 'right') {
        position.left = position.left - 80;
      }

      this.annotationNode.setAttribute('style', `left: ${position.left}px; top: ${position.top}px`);
      this.annotationNode.innerHTML = this.allowAnnotations ? adminAnnotation : publicAnnotation;

      this.renderer.appendChild(this.containerSelector === 'ql-editor' ?
        this.annContainer.parentNode : this.annContainer, this.annotationNode);

      this.annotationNode.addEventListener('mouseleave', () => {
        if (!this.allowAnnotations) {
          this.removeAnnotationTooltip();
        }
      });

      if (this.allowAnnotations) {
        this.annotationNode.querySelector(`.btn.save`)
            .addEventListener('click', this.updateAnnotationText.bind(this), false);
        this.annotationNode.querySelector(`.btn.edit`)
            .addEventListener('click', this.editModeAnnPoint.bind(this), false);
        this.annotationNode.querySelector(`.btn.cancel`)
            .addEventListener('click', this.cancelAnnPoint.bind(this), false);
        this.annotationNode.querySelector(`.btn.close-an`)
          .addEventListener('click', this.removeAnnotationTooltip.bind(this), false);
        this.annotationNode.querySelector(`.btn.delete`)
            .addEventListener('click', this.deleteAnnPoint.bind(this), false);
        this.annotationNode.querySelector(`.btn.link`)
            .addEventListener('click', this.addLink.bind(this), false);
        this.annotationNode.querySelector(`.btn.add-link`)
            .addEventListener('click', this.injectLinkToText.bind(this), false);
        this.annotationNode.querySelector('.edit-text')
            .addEventListener('keyup', this.setAnnotationText.bind(this), false);
        this.annotationNode.querySelector('.edit-text')
            .addEventListener('click', this.setAnnotationText.bind(this), false);
        this.annotationNode.querySelector('.edit-text')
          .addEventListener('paste', this.setAnnotationTextOnPaste.bind(this));

      }

    }

    removeAnnotationTooltip() {
      const annotationNodes = document.querySelectorAll('.annotation-tooltip');

      Array.from(annotationNodes).forEach(annTooltip => {
        this.renderer.removeChild(this.containerSelector === 'ql-editor' ?
          this.annContainer.parentNode : this.annContainer, annTooltip);

        if (this.allowAnnotations) {
          this.annotationNode.querySelector(`.btn.save`)
            .removeEventListener('click', this.updateAnnotationText.bind(this), false);
          this.annotationNode.querySelector(`.btn.edit`)
            .removeEventListener('click', this.editModeAnnPoint.bind(this), false);
          this.annotationNode.querySelector(`.btn.cancel`)
            .removeEventListener('click', this.cancelAnnPoint.bind(this), false);
          this.annotationNode.querySelector(`.btn.delete`)
            .removeEventListener('click', this.deleteAnnPoint.bind(this), false);
          this.annotationNode.querySelector(`.btn.close-an`)
            .removeEventListener('click', this.removeAnnotationTooltip.bind(this), false);
          this.annotationNode.querySelector(`.btn.link`)
            .removeEventListener('click', this.addLink.bind(this), false);
          this.annotationNode.querySelector(`.btn.add-link`)
            .removeEventListener('click', this.injectLinkToText.bind(this), false);
          this.annotationNode.querySelector('.edit-text')
            .removeEventListener('keyup', this.setAnnotationText.bind(this), false);
          this.annotationNode.querySelector('.edit-text')
            .removeEventListener('click', this.setAnnotationText.bind(this), false);
        }
      });
    }

  getSelectionHtml() {
    let html = '';
    if (typeof window.getSelection !== 'undefined') {
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const container = document.createElement('div');
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML;
      }
    } else if (typeof document['selection'] !== 'undefined') {
      if (document['selection'].type === 'Text') {
        html = document['selection'].createRange().htmlText;
      }
    }
    return html;
  }
}
