import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
import {AdminService} from '../services/admin.service';
import {NoteService} from '../../app/shared/services/note_service';

import {environment} from '../../environments/environment';

import {NoteFlag} from '../../app/shared/types/note_flag';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../app/shared/confirmation-modal/confirmation-modal.component';
import { Note } from '../../app/shared/types/note';


@Component({
  selector: 'notd-boffice-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @ViewChild('deleteNotesModal', {static: false}) deleteNotesModal: ElementRef;
  @ViewChild('deleteSingleNoteModal', {static: false}) deleteSingleNoteModal: ElementRef;

  notesList = [];
  notesTotal = 0;
  searchParam: string;
  loading = false;
  noteIdToDelete = '';
  offset = 0;
  notesOnPage = 40;
  multiSelectList = [];
  public featuredPriorities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private noteService: NoteService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(res => {
      this.searchParam = res.searchString;
      this.makeNotesSearch(this.searchParam, this.notesOnPage, this.offset);
    });
  }

  openNoteDetails(note) {
    // for localhost
    const url = (environment.urlBasePrefix.indexOf('4201') > 0) ?
      environment.urlBasePrefix.replace('4201', '4200') : environment.urlBasePrefix;

    window.open(`${url}/notes/${note.id}/${note.name}`, '_blank');
  }

  makeNotesSearch(query?: string, size?: number, fromOffset?: number) {
    this.loading = true;
    this.adminService.getNotes({q: query, size, fromOffset}).then((res: any) => {
      this.notesList = res.posts;
      this.notesTotal = res.total;
      this.loading = false;
      this.notesList.map(note => {
        note.externalLink = `${environment.urlBasePrefix}/notes/${note.id}/${note.indexedName || note.name}`;
        this.getNoteFlags(note.id);
      });
    });
  }

  submitNoteSearch(event: Event) {
    this.searchParam = event.target[0].value;
    this.makeNotesSearch(this.searchParam, this.notesOnPage, 0);
  }

  getNoteFlags(noteId: string) {
    this.adminService.getNoteFlags(noteId).then(res => {
      const note = this.notesList.filter(n => n.id === noteId)[0];
      if (note) {
        Object.assign(note, {
          states: res
        });
      }
    });
  }

  flagNote(flag: NoteFlag) {
    this.showChangeFlagModal({
      title: `React on note flag - '${flag.state}'`,
      question: 'What kind of action should be done?',
      flag
    });
  }

  showChangeFlagModal(modalDetail: {title: string; question: string; flag?: any}) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = modalDetail.title;
    refModalConf.componentInstance.confirmationModalMsg = modalDetail.question;
    refModalConf.componentInstance.buttons = {
      first: 'Accept',
      second: 'Reject'
    };

    refModalConf.result.then(resp => {
      if (resp) {
        this.adminService.setNoteTakeDownFlag('accept', modalDetail.flag.id).then(() => {
          this.makeNotesSearch(this.searchParam, this.notesOnPage, 0);
        });
      } else {
        this.adminService.setNoteTakeDownFlag('reject', modalDetail.flag.id).then(() => {
          this.makeNotesSearch(this.searchParam, this.notesOnPage, 0);
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  deleteNote(noteId: string) {
    this.noteIdToDelete = noteId;
    this.showConfirmationModal({
      title: `Delete note confirmation`,
      question: 'Do you really want to remove this note?'
    });
  }

  deleteSingleNote(noteId: number): void {
    const refModalConf = this.modalService.open(this.deleteSingleNoteModal, {});

    refModalConf.result.then((resp: any) => {
      if (resp) {
        if (resp === 'trash') {
          this.adminService.deleteNote(noteId).then(() => {
            console.log('Note was moved to trash.');
            setTimeout(() => {
              this.getNotes();
            }, 1000);
          });
        }
        if (resp === 'delete') {
          this.adminService.deleteNote(noteId, true).then(() => {
            console.log('Note was removed permanently.');
            setTimeout(() => {
              this.getNotes();
            }, 1000);
          });
        }
      } else {
        console.log('else');
      }
    }).catch(err => {
      console.error(err, 'quit the modal');
    });
  }

  showConfirmationModal(modalDetail: {title: string; question: string}) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = modalDetail.title;
    refModalConf.componentInstance.confirmationModalMsg = modalDetail.question;
    refModalConf.componentInstance.buttons = {
      first: 'Delete',
      second: 'Cancel'
    };

    refModalConf.result.then(resp => {
      if (resp) {
        this.noteService.deleteNote(this.noteIdToDelete).subscribe(() => {
          this.makeNotesSearch(this.searchParam, this.notesOnPage, 0);
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  public restoreNoteModal(noteId: number): void {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = 'Restore your note';
    refModalConf.componentInstance.confirmationModalMsg = 'Do you want to restore your deleted note?';

    refModalConf.result.then(resp => {
      if (resp) {
        this.adminService.restoreNote(noteId, 'PUBLIC').then(() => {
          this.getNotes();
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  public deleteForeverModal(noteId: number): void {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = 'Delete your note permanently';
    refModalConf.componentInstance.confirmationModalMsg = 'Are you sure you want to delete this note permanently?';

    refModalConf.result.then(resp => {
      if (resp) {
        this.adminService.deleteNote(noteId, true).then(() => {
          console.log('Note was removed permanently.');
          this.getNotes();
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  paginationPageChanged(event: Event) {
    const paginationOff = Number(event);
    this.offset = paginationOff - 1;
    this.makeNotesSearch(this.searchParam, this.notesOnPage, this.offset * this.notesOnPage);
  }

  multiSelect(value: any, note: any) {
    const filterResult = this.multiSelectList.filter(n => n.id === note.id);

    if (filterResult && filterResult.length > 0) {
      this.multiSelectList = this.multiSelectList.filter(n => n.id !== note.id);
    } else {
      this.multiSelectList.push({
        id: note.id,
        title: note.name
      });
    }
  }

  deleteSelectedNotes() {
    const modalOptions: NgbModalOptions = {};

    const refModalConf = this.modalService.open(this.deleteNotesModal, modalOptions);

    refModalConf.result.then(resp => {
      if (resp === 'delete') {
        const promises = [];

        this.multiSelectList.map(n => {
          promises.push(this.noteService.deleteNote(n.id).toPromise());
        });

        Promise.all(promises).then(() => {
          this.offset = 0;
          this.multiSelectList = [];
          this.makeNotesSearch(this.searchParam, this.notesOnPage, this.offset);
        }, err => {
          console.log(err);
        });
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  existOnMultiList(noteId: string | number) {
    return this.multiSelectList.filter(n => n.id === noteId).length;
  }

  public changeFeaturePriority(ev: any, note: Note): void {
    const featuredOrder = ev.target['value'];
    this.adminService.changeFeaturedNotePriority(note.id, featuredOrder);
  }

  private getNotes(): void {
    this.loading = true;
    this.offset = 0;
    this.makeNotesSearch(this.searchParam, this.notesOnPage, this.offset);
  }
}
