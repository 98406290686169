import { UserService } from './../../app/shared/services/user_service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {User} from '../../app/shared/types/user';
import {Stream} from '../../app/shared/types/stream';
import {Comment} from '../../app/shared/types/comment';
import {PayoutDestination} from '../../app/shared/types/payout_destination';
import {PayoutStatus} from '../../app/shared/types/payout_status';
import {Payout} from '../../app/shared/types/payout';

import {StreamService} from '../../app/shared/services/stream_service';
import {NoteService} from '../../app/shared/services/note_service';
import {AdminService} from '../services/admin.service';

import {environment} from '../../environments/environment';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../app/shared/confirmation-modal/confirmation-modal.component';
import { TransferMethod } from '../../app/shared/types/bank';


export interface BankingInfo {
  ccNumLast4: number;
  accountHolder: string;
  created: number;
  name: string;
  routingNumber: string | number;
  transferMethod: number;
  transferMethodTitle: string;
  userId: number | string;
  user: User;
}

@Component({
  selector: 'notd-user-profile',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('streamPayments', {static: false}) streamPayments: ElementRef;

  loadingProfile = true;
  user: User;
  userStreams = [];
  loadingUserStreams = true;
  userComments = [];
  loadingUserComments = true;
  userPayouts = [];
  loadingUserPayouts = true;
  payoutDestinationType = PayoutDestination;
  payoutStatus = PayoutStatus;
  // eslint-disable-next-line @typescript-eslint/ban-types
  streamPaymentsDetail: object;
  edit = {
    displayName: {
      display: false
    }
  };

  public externalLink: {
    user: string;
  } = {
    user: null
  };

  public bankingInfo: {
    list: BankingInfo | BankingInfo[] | null;
    loading: boolean
  } = {
    list: null,
    loading: true
  };

  constructor(
      private router: Router,
      private userService: UserService,
      private streamService: StreamService,
      private noteService: NoteService,
      private adminService: AdminService,
      private modalService: NgbModal,
      private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      const userId = param.id;
      this.getUserProfile(userId);
      this.getStreamsByUserId(userId);
      this.getUserCommentsList(userId);
      this.getUserPayouts(userId);
      this.getUserBankingInfo(userId);
    });
  }

  getUserProfile(userId: string) {
    this.userService.getUserProfile(userId, true).then((res: User) => {
      this.loadingProfile = false;
      this.user = res;
      this.externalLink.user = `${environment.urlBasePrefix}/users/${res.id}/profile`;
    });
  }

  getStreamsByUserId(userId: string) {
    this.streamService.getStreamsByUserId(userId).then((streamList: Stream[]) => {
      this.loadingUserStreams = false;
      this.userStreams = streamList;

      this.userStreams.map((s: Stream) => {
        s['externalLink'] = `${environment.urlBasePrefix}/streams/${s.id}/view/${s.indexedName || s.name}`;

        this.adminService.getStreamPaymentsSummary(s.id, 'DAYS', 720).then(res => {
          Object.assign(s, {
            payments: res,
          });
        });
      });
    });
  }

  getUserCommentsList(userId: string) {
    this.userService.getUserCommentsList(userId).then((commentsList: Comment[]) => {
      this.loadingUserComments = false;
      commentsList.map((c: Comment) => {
        this.noteService.getNoteInfo(c.postId).then((note) => {
          this.userComments.push({
            noteId: note.id,
            noteName: note['name'],
            comment: c.comments,
            created: c.created,
            likes: c.likes,
            dislikes: c.dislikes,
            streamName: (note['_streamInfo']) ? note['_streamInfo']['name'] : 'no stream name',
            streamId: (note['_streamInfo']) ? note['_streamInfo']['id'] : 'no stream id'
          });
        });
      });
    });
  }

  getUserPayouts(userId: string) {
    this.adminService.getUserPayouts(userId).then((payouts: Payout[]) => {
      this.userPayouts = payouts;
      this.loadingUserPayouts = false;
    });
  }

  openAppNote(noteId: string, noteName: string) {
    window.open(`${environment.urlBasePrefix}/notes/${noteId}/${noteName}`, '_blank');
  }

  openAppStream(streamId: string, streamName: string) {
    window.open(`${environment.urlBasePrefix}/streams/${streamId}/${streamName}`, '_blank');
  }

  getTaxDoc() {
    return document.location.assign(this.adminService.getUserTaxDocPath(this.user.id));
  }

  getIdentityDoc() {
    return document.location.assign(this.adminService.getUserIdentityDocPath(this.user.id));
  }

  showStreamPayments(stream: Stream | any) {
    this.streamPaymentsDetail = stream;

    const modalOptions: NgbModalOptions = {};

    modalOptions.size = 'lg';
    this.modalService.open(this.streamPayments, modalOptions);
  }

  deleteUser() {
    this.showConfirmationModal({
      title: `Delete user confirmation`,
      question: 'Do you really want to remove this user?',
      type: 'delete'
    });
  }

  banUser() {
    this.showConfirmationModal({
      title: `${this.user['banned'] ? 'Unban' : 'Ban'} user confirmation`,
      question: `Do you want to ${this.user['banned'] ? 'unban' : 'ban'} this user?`,
      type: 'ban'
    });
  }

  changeDisplayName(event: Event) {
    Object.assign(this.user, {
      displayName: event
    });

    this.adminService.setUserName(this.user.id, this.user).then(() => {
      this.getUserProfile(this.user.id);
    });
  }

  showConfirmationModal(modalDetail: {title: string; question: string; type: string}) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = modalDetail.title;
    refModalConf.componentInstance.confirmationModalMsg = modalDetail.question;
    refModalConf.componentInstance.buttons = {
      first: 'Yes',
      second: 'No'
    };

    refModalConf.result.then(resp => {
      if (resp) {
        switch (modalDetail.type) {
          case 'ban':
            this.adminService[this.user['banned'] ? 'unbanUser' : 'banUser'](this.user.id).then(() => {
              this.getUserProfile(this.user.id);
            });
            break;
          case 'delete':
            this.adminService.deleteUser(this.user.id).then(() => {
              this.router.navigate(['./users']);
            });
            break;
        }
      }
    }).catch(err => {
      console.log(err, 'quit the modal');
    });
  }

  private getUserBankingInfo(userId: number | string): void {
    this.adminService.getUserBankingInfo(userId).then((bankingInfo: BankingInfo | BankingInfo[]) => {
      
      this.bankingInfo = {
        list: bankingInfo,
        loading: false
      };

      for (const [key, v] of Object.entries(this.bankingInfo.list)) {
        v.transferMethodTitle = TransferMethod[v.transferMethod]
        this.userService.getUserProfile(v.userId).then((user: User) => {
          v.user = user;
        });
      }
    })
  }
}
