import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ButtonLoaderDirective} from './button-loader.component';

@NgModule({
    declarations: [
        ButtonLoaderDirective
    ],
    imports: [
        RouterModule,
        CommonModule,
        RouterModule
    ],
    exports: [ ButtonLoaderDirective ]
})

export class ButtonLoaderModule {}
