import * as Debug from 'debug';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
const debug = Debug('notd:backoffice:LoginRedirectGuard');

@Injectable()
export class LoginRedirectGuard  {

  constructor(
    private userService: UserService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getProfile();
  }

  getProfile(): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        this.userService.getProfile().then((user) => {
          if (user && user.id !== '' && user.hasOwnProperty('admin') && user['admin'] === 1) {
            resolve(true);
          } else {
            this.userService.login().then((response) => {
              debug('userService.login response', response);

              resolve(false);
            });
          }
        }).catch( err => {
          resolve(false);
          this.router.navigate(['./login']);
        });
      });
  }

}
