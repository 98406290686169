import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ButtonLoaderModule} from '../../app/shared/button-loader/button-loader.module';

import {LoginComponent} from './login.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faSync, faCircle, faCircleInfo, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    RouterModule.forChild([{ path: '', component: LoginComponent }]),
    CommonModule,
    FormsModule,
    ButtonLoaderModule,
    FontAwesomeModule
  ],
  providers: [
  ]
})
export class LoginModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faInfoCircle, faCircleNotch);
  }
}
