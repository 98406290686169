import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {StreamsComponent} from './streams.component';
import {SideMenuModule} from '../side-menu/side-menu.module';
import {ConfirmationModalModule} from '../../app/shared/confirmation-modal/confirmaton-modal.module';
import {RouterModule} from '@angular/router';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faList, faStar, faStickyNote, faSync, faUsers} from '@fortawesome/free-solid-svg-icons';
import {faMoneyBillAlt} from '@fortawesome/free-regular-svg-icons';
import { ExternalLinkModule } from '../shared/external-link-icon/external-link-icon.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { ColumnSorterModule } from '../shared/column-sorter/column-sorter.module';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: StreamsComponent }]),
    CommonModule,
    FormsModule,
    SideMenuModule,
    ConfirmationModalModule,
    FontAwesomeModule,
    ExternalLinkModule,
    NgbPaginationModule,
    ColumnSorterModule
  ],
  declarations: [
    StreamsComponent
  ],
  providers: [
  ]
})
export class StreamsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faList, faMoneyBillAlt, faStar, faStickyNote, faUsers);
  }
}
