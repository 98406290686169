import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
@Component({
  selector: 'field-editor',
  templateUrl: './field-editor.component.html',
  styleUrls: ['./field-editor.component.scss']
})
export class FieldEditorComponent implements OnInit, OnChanges {
  @Input() sourceModel;
  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  display = false;
  prevModelValue: string;

  ngOnInit() {
    this.prevModelValue = this.sourceModel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sourceModel) {
      this.prevModelValue = changes.sourceModel.currentValue;
    }
  }

  changeDisplayTo(status: boolean) {
    this.display = status;
    this.sourceModel =  this.prevModelValue;
  }

  saveValue() {
    this.display = false;
    this.changeValue.emit(this.sourceModel);
  }
}
