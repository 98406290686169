import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ExternalLinkComponent} from './external-link-icon.component';

@NgModule({
    declarations: [
      ExternalLinkComponent
    ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [ ExternalLinkComponent ]
})

export class ExternalLinkModule { }
