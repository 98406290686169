import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {User} from '../../../app/shared/types/user';
import {Stream} from '../../../app/shared/types/stream';
import {Payment, PaymentProcessor, PaymentState, PaymentType} from '../../../app/shared/types/payment';

import {UserService} from '../../../app/shared/services/user_service';
import {StreamService} from '../../../app/shared/services/stream_service';
import {NoteService} from '../../../app/shared/services/note_service';
import {AdminService} from '../../services/admin.service';

import {NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notd-backoffice-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  loadingSearchResults = true;
  stream: Stream;
  searchResults = [];

  paymentState = PaymentState;
  paymentProcessor = PaymentProcessor;
  paymentType = PaymentType;

  fromDate: any;
  toDate: any;

  constructor(
      private router: Router,
      private userService: UserService,
      private streamService: StreamService,
      private noteService: NoteService,
      private adminService: AdminService,
      private modalService: NgbModal,
      private calendar: NgbCalendar,
      private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
      this.getStreamDetails(params.id);
      this.getStreamSales(params.id);
    });
  }

  getStreamDetails(streamId: string) {
    this.streamService.getStreamInfoObservable(streamId, true).subscribe((res: Stream) => {
      this.stream = res;
      this.loadingSearchResults = false;
    });
  }

  getStreamSales(streamId: string) {
    this.adminService.getStreamSales(streamId, {}).then((res: Payment | any) => {
      this.loadingSearchResults = false;
      this.searchResults = res;
      this.getPaymentDetails();
    });
  }

  searchForResult() {
    this.loadingSearchResults = true;
    const fromDateTimestamp = this.fromDate ? new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day).getTime() : '';
    const toDateTimestamp = this.toDate ? new Date(this.toDate.year, this.toDate.month, this.toDate.day).getTime() : '';
    const query = {
      fromDate: fromDateTimestamp,
      toDate: toDateTimestamp
    };

    this.adminService.getStreamSales(this.stream.id, query)
      .then((res: Payment | any) => {
        this.loadingSearchResults = false;
        this.searchResults = res;

        this.getPaymentDetails();
      });
  }

  getPaymentDetails() {
    this.searchResults.map((payment: Payment) => {
      this.userService.getUserProfile(payment.sellerId).then((resp: User) => {
        Object.assign(payment, {
          displaySellerName: resp.displayName
        });
      });

      this.userService.getUserProfile(payment.buyerId).then((resp: User) => {
        Object.assign(payment, {
          displayBuyerName: resp.displayName
        });
      });
    });
  }
}
