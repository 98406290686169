<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    List of payments: <a [routerLink]="['/stream', stream.id]" *ngIf="stream" class="strong color-blue" title="{{stream.name}}">{{stream.name}}</a> <span *ngIf="stream" class="small color-carmine"> (id: {{stream.id}})</span>
    <fa-icon [icon]="['fas', 'list']"></fa-icon>
  </h3>
  <div class="search-box">
    <label>
      Search for stream sales
    </label>
    <div class="datepickers">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="d1" [(ngModel)]="fromDate" ngbDatepicker #d1="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
                <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="d2" [(ngModel)]="toDate" ngbDatepicker #d2="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
      <button class="btn btn-primary mb-2" type="submit" (click)="searchForResult()">Search</button>
    </div>
  </div>
  <ng-container *ngIf="searchResults?.length > 0 && !this.loadingSearchResults">
    <table class="table notd-backoffice-table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col" class="text-center">Processor</th>
          <th scope="col" class="text-center">Buyer</th>
          <th scope="col" class="text-center">Seller</th>
          <th scope="col" class="text-center">State</th>
          <th scope="col" class="text-center">Pay Type</th>
          <!--<th scope="col" class="text-center">Sub Plan Id</th>-->
          <!--<th scope="col" class="text-center">Sub Id</th>-->
          <!--<th scope="col" class="text-center">Publisher</th>-->
          <th scope="col" class="text-center">Price</th>
          <th scope="col" class="text-center">Commission</th>
          <th scope="col" class="text-center">Earns</th>
          <th scope="col" class="text-center">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sale of searchResults; let i = index">
          <td>{{i+1}}</td>
          <td class="text-center">{{paymentProcessor[sale['processor']]}}</td>
          <td class="text-center">
            <fa-icon *ngIf="!sale.displayBuyerName" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <div *ngIf="sale.displayBuyerName">
              <a [routerLink]="['/user', sale.buyerId]" title="{{sale.displayBuyerName}}" class="link">{{sale.displayBuyerName}}</a>
            </div>
          </td>
          <td class="text-center">
            <fa-icon *ngIf="!sale.displaySellerName" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <div *ngIf="sale.displaySellerName">
              <a [routerLink]="['/user', sale.sellerId]" title="{{sale.displaySellerName}}" class="link">{{sale.displaySellerName}}</a>
            </div>
          </td>
          <td class="text-center">{{paymentState[sale['state']]}}</td>
          <td class="text-center">{{(paymentType[sale['payType']]).replace('_', ' ')}}</td>
          <!--<td class="text-center">{{sale['subPlanId']}}</td>-->
          <!--<td class="text-center">{{sale['subId']}}</td>-->
          <!--<td class="text-center">-->
            <!--<span *ngIf="!sale.displayPublisherName" class="fa fa-refresh fa-spin"></span>-->
            <!--<div *ngIf="sale.displayPublisherName">-->
              <!--<a [routerLink]="['/user', sale.publisherId]" title="{{sale.displayPublisherName}}" class="link">{{sale.displayPublisherName}}</a>-->
            <!--</div>-->
          <!--</td>-->
          <td class="text-center">{{sale.price / 100 | currency:'USD':'symbol'}}</td>
          <td class="text-center">{{sale.commission_cc / 10000 | currency:'USD':'symbol'}}</td>
          <td class="text-center">{{sale.usrEarns_cc / 10000 | currency:'USD':'symbol'}}</td>
          <td class="text-center">{{sale.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="searchResults.length === 0 && !loadingSearchResults">
    <div class="message">
      There is no data in this criteria.
    </div>
  </ng-container>

  <ng-container *ngIf="loadingSearchResults">
    <div class="text-center">
      <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
    </div>
  </ng-container>
</div>
