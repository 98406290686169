<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">

  <div class="card cat-card o-hidden">
    <fa-icon class="bg-ico" [icon]="['fas', 'user-circle']"></fa-icon>
    <h2>Users</h2>
    <div class="details" *ngIf="dbUsers">
      <span>Number of users: <strong>{{numUsers}}</strong></span>
    </div>
    <div class="input-group">
      <form (ngSubmit)="searchFor('users')">
        <input class="form-control"
               [(ngModel)]="search.users"
               [ngModelOptions]="{standalone: true}"
               type="text"
               placeholder="Search for users...">
        <span class="input-group-append">
          <button class="btn btn-primary notd-bo-but notd-bo-users" type="button" (click)="searchFor('users')">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </button>
        </span>
      </form>
    </div>
  </div>

  <div class="card cat-card o-hidden h-100">
    <fa-icon class="bg-ico" [icon]="['far', 'id-card']"></fa-icon>
    <h2>Streams</h2>
    <div class="details" *ngIf="dbStreams">
      <span>Number of streams: <strong>{{numStreams}}</strong></span>
    </div>
    <div class="input-group">
      <form (ngSubmit)="searchFor('streams')">
        <input class="form-control"
               [(ngModel)]="search.streams"
               [ngModelOptions]="{standalone: true}"
               type="text"
               placeholder="Search for streams...">
        <span class="input-group-append">
          <button class="btn btn-primary notd-bo-but notd-bo-streams" type="button" (click)="searchFor('streams')">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </button>
        </span>
      </form>
    </div>
  </div>

  <div class="card cat-card o-hidden">
    <fa-icon class="bg-ico" [icon]="['fas', 'sticky-note']"></fa-icon>
    <h2>Notes</h2>
    <div class="details" *ngIf="dbNotes">
      <span>Number of notes: <strong>{{numNotes}}</strong></span>
    </div>
    <div class="input-group">
      <form (ngSubmit)="searchFor('notes')">
        <input class="form-control"
               [(ngModel)]="search.notes"
               [ngModelOptions]="{standalone: true}"
               type="text"
               placeholder="Search for notes...">
        <span class="input-group-append">
          <button class="btn btn-primary notd-bo-but notd-bo-users" type="button" (click)="searchFor('notes')">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </button>
        </span>
      </form>
    </div>
  </div>

</div>
