import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AnnotationDirective} from './annotation.directive';

@NgModule({
    declarations: [ AnnotationDirective ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [ AnnotationDirective ]
})

export class AnnotationModule {}
