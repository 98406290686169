import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SortByPipe} from '../pipes/sortby.pipe';
import {Subject} from 'rxjs';

export interface ColumnSorterInterface {
  field: string;
  list: any[];
}

@Component({
  selector: 'column-sorter',
  templateUrl: './column-sorter.component.html',
  styleUrls: ['./column-sorter.component.scss'],
  providers: [ SortByPipe ]

})
export class ColumnSorterComponent implements OnInit, OnDestroy {
  public static columnSortedSubject: Subject<ColumnSorterInterface> = new Subject();

  @Input() text: string;
  @Input() field: string;
  @Input() list: any[];

  direction: boolean;
  columnSortSubscriber;

  constructor(private sortPipe: SortByPipe) { }

  ngOnInit() {
    this.columnSortSubscriber = ColumnSorterComponent.columnSortedSubject.subscribe((col: ColumnSorterInterface) => {
      if (this.field !== col.field && this.list === col.list) {
        this.direction = undefined;
        return;
      }
    });
  }

  labelSortBy() {
    this.sortBy(!this.direction);
  }

  sortBy(direction = true) {
    let sort: string = this.field;
    this.direction = direction;
    if (!direction) {
      sort = '-' + sort;
    }

    this.sortPipe.transform(this.list, [sort]);

    ColumnSorterComponent.columnSortedSubject.next(
    {
      field: this.field,
      list: this.list
    });
  }

  ngOnDestroy() {
    if (this.columnSortSubscriber) {
      this.columnSortSubscriber.unsubscribe();
    }
  }
}
