import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SideMenuComponent} from './side-menu.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faComments, faList, faMoneyCheckDollar, faSync, faTachometerAlt} from '@fortawesome/free-solid-svg-icons';
import {faStickyNote} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [ SideMenuComponent ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [ SideMenuComponent ]
})

export class SideMenuModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faStickyNote, faTachometerAlt, faList, faComments, faMoneyCheckDollar);
  }
}
