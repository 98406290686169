<section
  *ngIf="!isLogging"
  class="login-modal">
  <fa-icon class="bg-icon" [icon]="['fas', 'info-circle']"></fa-icon>
  <p>Please log-in to the dashboard.</p>
  <div class="button-group">
    <button (click)="login(); loadingLoginPage = true;" class="btn btn-primary" title="Login">
      Login
      <fa-icon *ngIf="loadingLoginPage" class="button-loader" [icon]="['fas', 'circle-notch']" [spin]="true"></fa-icon>
    </button>
  </div>
</section>

<section
  *ngIf="isLogging"
  class="waiting-for-login">
  <fa-icon class="loader" [icon]="['fas', 'circle-notch']" [spin]="true"></fa-icon>
  Logging in, please wait...
</section>
