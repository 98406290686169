<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header" *ngIf="user">
    User profile: <strong>{{user?.firstName}} {{user?.lastName}}</strong> <span class="small color-carmine"> (id: {{user?.id}})</span>
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
    <external-link-icon
      *ngIf="externalLink?.user"
      [label]="'Show User profile on Notd'"
      [title]="'Show user profile on Notd'"
      [link]="externalLink.user"></external-link-icon>
  </h3>
  <div class="loading-spinner-holder" *ngIf="loadingProfile">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div class="profile-wrapper" *ngIf="!loadingProfile">
    <div class="profile-info">
      <div class="avatar">
        <img *ngIf="user?.profileImageUrl" class="rounded" [src]="user.profileImageUrl" />
        <img *ngIf="!user?.profileImageUrl" class="rounded" [src]="'assets/svg/user.svg'" />
        <div class="buttons">
          <button class="btn red" (click)="deleteUser()">Delete user</button>
          <button class="btn mt-5" [ngClass]="{'black' : user['banned'], 'blue' : !user['banned']}" (click)="banUser()">{{user['banned'] ? 'Unban' : 'Ban'}} this user</button>
        </div>
        <div class="earning-spending" *ngIf="user">
          <h3 class="sub-header xs-size mt-10">
            Spending / Earnings
            <fa-icon [icon]="['fas', 'money-bill-alt']"></fa-icon>
          </h3>
          <ul>
            <li>
              <a [routerLink]="['/user', user.id, 'earnings']" title="Show user earnings">Show user earnings</a>
            </li>
            <li>
              <a [routerLink]="['/user', user.id, 'spendings']" title="Show user spendings">Show user spendings</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="profile-details">
        <div class="text-label mb-10">
          <label>Status:</label>
          <span class="text">
            <span class="tag" [ngClass]="{'active' : user['admin'] ? true : false}">admin</span>
            <span class="tag" [ngClass]="{'active' : user['banned'] ? true : false}">banned</span>
            <span class="tag" [ngClass]="{'active' : user['accountClosed'] ? true : false}">account closed</span>
            <span class="tag" [ngClass]="{'active' : user['notifyFollow'] ? true : false}">notify follow</span>
            <span class="tag" [ngClass]="{'active' : user['notifyMyComms'] ? true : false}">notify my comments</span>
            <span class="tag" [ngClass]="{'active' : user['notifyMyPosts'] ? true : false}">notify my posts</span>
          </span>
        </div>
        <div class="text-label">
          <label>Account created:</label>
          <span class="text">{{user.created | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="text-label">
          <label>User name:</label>
          <span class="text">{{user.firstName}} {{user.lastName}}</span>
        </div>
        <div class="text-label">
          <label>User e-mail:</label>
          <span class="text">{{user.email1}}</span>
        </div>
        <div class="text-label">
          <label>System user name:</label>
          <span class="text">{{user.userName ? user.userName : 'no system user name'}}</span>
        </div>
        <div class="text-label">
          <label>Display name:</label>
          <field-editor [sourceModel]="user.displayName" (changeValue)="changeDisplayName($event)"></field-editor>
        </div>
        <div class="text-label">
          <label>Funds:</label>
          <span class="text" *ngIf="user && user.funds">{{user.funds['PROD'] / 10000 | currency:'USD':'symbol'}}</span>
          <span class="text" *ngIf="user && !user.funds">{{0 | currency:'USD':'symbol'}}</span>
        </div>
        <div class="text-label">
          <label>Tax document:</label>
          <span class="text" *ngIf="!user['taxDoc']">none</span>
          <a class="text link" (click)="getTaxDoc()" *ngIf="user['taxDoc']">Click here to see tax document</a>
        </div>
        <div class="text-label">
          <label>Identity document:</label>
          <span class="text" *ngIf="!user['identityDoc']">none</span>
          <a class="text link" (click)="getIdentityDoc()" *ngIf="user['identityDoc']">Click here to see identity document</a>
        </div>
      </div>
    </div>

    <div class="subcontent-wrapper mt-20">
      <h3 class="sub-header x-size">
        Banking Info
        <fa-icon [icon]="['fas', 'building-columns']"></fa-icon>
      </h3>
      <section *ngIf="bankingInfo.loading" class="bo__loading banking-loader">
        <span class="bo__loading__spinner"></span>
        <p class="bo__loading__label">Loading banking info...</p>
      </section>
      <table *ngIf="!bankingInfo.loading && bankingInfo.list.length" class="bo__table text--centered">
        <thead>
          <tr>
            <th>
              <column-sorter [text]="'Account Holder'" [list]="bankingInfo.list" [field]="'accountHolder'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Account Number'" [list]="bankingInfo.list" [field]="'accountNumber'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Account Last 4-dig'" [list]="bankingInfo.list" [field]="'accNumLast4'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Name'" [list]="bankingInfo.list" [field]="'name'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Routing Number'" [list]="bankingInfo.list" [field]="'routingNumber'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Transfer Method'" [list]="bankingInfo.list" [field]="'transferMethod'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'User'" [list]="bankingInfo.list" [field]="'userId'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Created'" [list]="bankingInfo.list" [field]="'created'"></column-sorter>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bi of bankingInfo.list">
            <td>
              {{ bi.accountHolder || '-' }}
            </td>
            <td>
              {{ bi.accountNumber || '-'  }}
            </td>
            <td>
              {{ bi.accNumLast4 || '-'  }}
            </td>
            <td>
              {{ bi.name || '-'  }}
            </td>
            <td>
              {{ bi.routingNumber || '-'  }}
            </td>
            <td>
              {{ bi.transferMethodTitle || '-'  }}
            </td>
            <td>
              <a [routerLink]="['/user', bi.userId]" *ngIf="bi.user">{{bi.user.displayName || bi.user.firstName + ' ' + bi.user.lastName}}</a>
            </td>
            <td>
              {{ bi.created | date: 'MMM dd, yyyy' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="streams-wrapper mt-20">
      <h3 class="sub-header x-size">
        User streams <span class="small color-carmine"> ({{userStreams?.length}})</span>
        <fa-icon [icon]="['fas', 'newspaper']"></fa-icon>
      </h3>
      <table class="table notd-backoffice-table" *ngIf="!loadingUserStreams && userStreams.length > 0">
        <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Notd stream external profile</th>
          <th scope="col" class="text-center">Notes</th>
          <th scope="col" class="text-center">Followers</th>
          <th scope="col" class="text-center">Subs</th>
          <th scope="col" class="text-center">Purchases</th>
          <th scope="col" class="text-center">Payments</th>
          <th scope="col" class="text-center">Views</th>
          <th scope="col" class="text-center">Rating</th>
          <th scope="col" class="text-center">Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stream of userStreams; let i = index">
          <td>{{i+1}}</td>
          <td>
            <a [routerLink]="['/stream', stream.id]" class="link" title="{{stream.name}}">{{stream.name}}</a>
          </td>
          <td>
            <external-link-icon
              *ngIf="stream.externalLink"
              [title]="'View stream on notd'"
              [link]="stream.externalLink"></external-link-icon>
          </td>
          <td class="text-center">
            {{stream.numPosts}}
          </td>
          <td class="text-center">{{stream.followers}}</td>
          <td class="text-center">{{stream.numSubs}}</td>
          <td class="text-center">{{stream['numPurchases']}}</td>
          <td class="text-center">
            <fa-icon *ngIf="!stream.payments" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <a class="link" *ngIf="stream?.payments" (click)="showStreamPayments(stream)">show details (<strong>{{stream?.payments.length}}</strong>)</a>
          </td>
          <td class="text-center">{{stream.numViews}}</td>
          <td class="text-center">{{stream.ratingAvg | number:'1.2-2'}}</td>
          <td class="text-center">{{stream.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loadingUserStreams">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
      </div>
    </div>

    <div class="payouts-wrapper mt-20">
      <h3 class="sub-header x-size">
        User payouts <span class="small color-carmine"> ({{userPayouts?.length}})</span>
        <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
      </h3>
      <table class="table notd-backoffice-table small" *ngIf="!loadingUserPayouts && userPayouts.length > 0">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="align-middle text-center">#</th>
          <th scope="col" class="align-middle">Bank info</th>
          <th scope="col" class="align-middle text-center">Destination</th>
          <th scope="col" class="align-middle text-center">Status</th>
          <th scope="col" class="align-middle text-center">Amounts<br /><span class="small">(req / sent)</span></th>
          <th scope="col" class="align-middle text-center">Request Ip Address</th>
          <th scope="col" class="align-middle text-center">Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let payout of userPayouts; let i = index" (click)="openPayoutDetails(payout)">
          <td>{{i+1}}</td>
          <td class="small">
            <span class="line"><strong>bank name:</strong> {{payout.bank.name}}</span>
            <span class="line"><strong>iban:</strong> {{payout.bank.iban || 'none'}}</span>
            <span class="line"><strong>swift:</strong> {{payout.bank.swift || 'none'}}</span>
            <span class="line"><strong>account holder:</strong> {{payout.bank.accountHolder}}</span>
            <span class="line"><strong>account number:</strong> {{payout.bank.accountNumber}}</span>
            <span class="line" *ngIf="payout.bank.routingNumber">
              <strong>routing number:</strong>
              {{payout.bank.routingNumber}}
            </span>
            <span class="line"><strong>transfer method:</strong> {{payout.bank.transferMethod}}</span>
          </td>
          <td class="text-center color-carmine strong">{{payoutDestinationType[payout.destType]}}</td>
          <td class="text-center color-blue strong">{{payoutStatus[payout.status]}}</td>
          <td class="text-center">
            {{payout.amountReq / 100}} / {{payout.amountSent / 100}}
          </td>
          <td class="text-center">{{payout.reqIpAddress}}</td>
          <td class="text-center">{{payout.created | date: 'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loadingUserPayouts">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
      </div>
    </div>

    <div class="comments-wrapper mt-20">
      <h3 class="sub-header  x-size">
        User comments <span class="small color-carmine"> ({{userComments?.length}})</span>
        <fa-icon [icon]="['fas', 'comments']"></fa-icon>
      </h3>
      <div class="comments-list" *ngIf="!loadingUserComments && userComments.length > 0">
        <div class="comment" *ngFor="let comment of userComments">
          <h2 class="note-title">
            <a class="note-name" (click)="openAppNote(comment.noteId, comment.noteName)" title="{{comment.noteName}}">{{comment.noteName}}</a>
            <a class="stream-name" (click)="openAppStream(comment.streamId, comment.streamName)" title="{{comment.streamName}}">{{comment.streamName}}</a>
            <span class="date">{{comment.created | date: 'dd/MM/yyyy'}}</span>
          </h2>
          <p class="comment-text">{{comment.comment}}</p>
          <div class="likes">
            <span>like: {{comment.likes}}</span> / <span>dislike: {{comment.dislikes}}</span>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="loadingUserComments">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #streamPayments let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Stream payments status</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3 class="sub-header x-size">
      {{streamPaymentsDetail.name}} <span class="small color-carmine"> ({{streamPaymentsDetail.payments?.length}})</span>
      <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
    </h3>
    <table class="table notd-backoffice-table small">
      <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col" class="text-center">Start balance</th>
        <th scope="col" class="text-center">End balance</th>
        <th scope="col" class="text-center">Sold Post Count</th>
        <th scope="col" class="text-center">Sold Sub Count</th>
        <th scope="col" class="text-center">Total sold</th>
        <th scope="col" class="text-center">User earn</th>
        <th scope="col" class="text-center">Created</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payment of streamPaymentsDetail.payments; let i = index">
        <td>{{i+1}}</td>
        <td class="text-center">{{payment['startBalance'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['endBalance'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['soldPostCount']}}</td>
        <td class="text-center">{{payment['soldSubCount']}}</td>
        <td class="text-center">{{payment['totalSold']}}</td>
        <td class="text-center">{{payment['usrEarns_cc'] / 10000 | currency:'USD':'symbol'}}</td>
        <td class="text-center">{{payment['created'] | date: 'dd/MM/yyyy'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="c('Cross click')">Close</button>
  </div>
</ng-template>

