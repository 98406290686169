import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlaggedComponent } from './flagged.component';
import { SideMenuModule } from '../../side-menu/side-menu.module';
import { RouterModule } from '@angular/router';
import { ConfirmationModalModule } from '../../../app/shared/confirmation-modal/confirmaton-modal.module';
import { ColumnSorterModule } from '../../shared/column-sorter/column-sorter.module';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faStickyNote, faSync} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: FlaggedComponent }]),
    CommonModule,
    FormsModule,
    SideMenuModule,
    ConfirmationModalModule,
    FontAwesomeModule,
    ColumnSorterModule
  ],
  declarations: [
    FlaggedComponent
  ],
  providers: [
  ]
})
export class FlaggedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faStickyNote);
  }
}
