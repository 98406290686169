import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserService} from '../../app/shared/services/user_service';
import {CacheService} from '../../app/shared/services/cache_service';

@Component({
  selector: 'notd-boffice-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loadingLoginPage = false;
  public isLogging = false;

  constructor(
      private userService: UserService,
      private cacheService: CacheService,
      private router: Router,
      private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.checkUser();

    this.isLogging = this.route.snapshot.queryParams['code'] ? true : false;

    if (this.isLogging) {
      const loggingInterval = setInterval(() => {
        this.userService.getProfile().then((user) => {
          if (user && user.id !== '' && user.hasOwnProperty('admin') && user['admin'] === 1) {
            clearInterval(loggingInterval);
            this.router.navigate(['/']);
          }
        });
      }, 1500);
    }
  }

  public login(): void {
    this.userService.logout().then(() => {
      this.userService.login().then((res) => {
        this.cacheService.clearCache();
      });
    });
  }

  private checkUser(): void {
    this.userService.getProfile().then((user) => {
      if (user && user.id !== '' && user.hasOwnProperty('admin') && user['admin'] === 1) {
        this.router.navigate(['/']);
      }
    });
  }
}
