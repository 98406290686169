import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {User} from '../../../app/shared/types/user';
import {Stream} from '../../../app/shared/types/stream';
import {Payment} from '../../../app/shared/types/payment';

import {UserService} from '../../../app/shared/services/user_service';
import {StreamService} from '../../../app/shared/services/stream_service';
import {NoteService} from '../../../app/shared/services/note_service';
import {AdminService} from '../../services/admin.service';

import {NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { AnyMxRecord } from 'dns';

@Component({
  selector: 'notd-user-transaction',
  templateUrl: './user-transaction.component.html',
  styleUrls: ['./user-transaction.component.scss']
})
export class UserTransactionComponent implements OnInit {
  @ViewChild('streamPayments', {static: false}) streamPayments: ElementRef;

  loadingProfile = true;
  user: User;
  urlParams: any;
  loadingSearchResults = true;

  fromDate: any;
  toDate: any;

  searchResults: any[] = [];

  constructor(
      private router: Router,
      private userService: UserService,
      private streamService: StreamService,
      private noteService: NoteService,
      private adminService: AdminService,
      private modalService: NgbModal,
      private calendar: NgbCalendar,
      private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.urlParams = param;
      this.getUseProfile(param.id);
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
      this.searchForResult();
    });

  }

  getUseProfile(userId: string) {
    this.userService.getUserProfile(userId).then((res: User) => {
      this.loadingProfile = false;
      this.user = res;
    });
  }

  searchForResult() {
    this.loadingSearchResults = true;
    const transactionType = (this.urlParams['transaction'] === 'earnings') ? 'sales' : 'purchases';
    const fromDateTimestamp = this.fromDate ? new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day).getTime() : '';
    const toDateTimestamp = this.toDate ? new Date(this.toDate.year, this.toDate.month, this.toDate.day).getTime() : '';
    this.adminService.getUserTransactions(this.urlParams['id'], transactionType, fromDateTimestamp, toDateTimestamp)
      .then((res: Payment | any) => {
        this.loadingSearchResults = false;
        this.searchResults = res;

        this.searchResults.map((payment: Payment) => {
          this.userService.getUserProfile(payment.sellerId).then((resp: User) => {
            Object.assign(payment, {
              displaySellerName: resp.displayName
            });
          });

          this.userService.getUserProfile(payment.buyerId).then((resp: User) => {
            Object.assign(payment, {
              displayBuyerName: resp.displayName
            });
          });

          this.streamService.getStreamInfoObservable(payment.channelId).subscribe((stream: Stream) => {
            Object.assign(payment, {
              streamName: stream.name
            });
          });
        });
      });
  }
}
