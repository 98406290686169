<notd-boffice-side-menu></notd-boffice-side-menu>
<div class="container-fluid">
  <h3 class="sub-header">
    User {{urlParams['transaction'] === 'earnings' ? 'Earnings' : 'Spendings'}}: <strong>{{user?.firstName}} {{user?.lastName}}</strong> <span class="small color-carmine"> (id: {{user?.id}})</span>
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
  </h3>
  <div class="loading-spinner-holder" *ngIf="loadingProfile">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div class="profile-wrapper" *ngIf="!loadingProfile">
    <div class="profile-info">
      <div class="avatar">
        <img *ngIf="user?.profileImageUrl" class="rounded" [src]="user.profileImageUrl" />
        <img *ngIf="!user?.profileImageUrl" class="rounded" [src]="'assets/svg/user.svg'" />
        <div class="earning-spending">
          <h3 class="sub-header xs-size mt-10">
            Spending / Earnings
            <fa-icon [icon]="['fas', 'money-bill-alt']"></fa-icon>
          </h3>
          <ul>
            <li *ngIf="urlParams['transaction'] === 'spendings'">
              <a [routerLink]="['/user', user.id, 'earnings']" title="Show user earnings">Show user earnings</a>
            </li>
            <li *ngIf="urlParams['transaction'] === 'earnings'">
              <a [routerLink]="['/user', user.id, 'spendings']" title="Show user spendings">Show user spendings</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="profile-details">
        <div class="search-box">
          <label>
            Search for user {{urlParams['transaction'] === 'earnings' ? 'Earnings' : 'Spendings'}}
          </label>
          <div class="datepickers">
            <form class="form-inline">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd"
                         name="d1" [(ngModel)]="fromDate" ngbDatepicker #d1="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
                      <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <form class="form-inline">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd"
                         name="d2" [(ngModel)]="toDate" ngbDatepicker #d2="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                      <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <button class="btn btn-primary mb-2" type="submit" (click)="searchForResult()">Search</button>
          </div>
        </div>
      </div>
    </div>

    <div class="streams-wrapper mt-20">
      <h3 class="sub-header x-size">
        User {{urlParams['transaction'] === 'earnings' ? 'Earnings' : 'Spendings'}} <span class="small color-carmine"> ({{searchResults?.length}})</span>
        <fa-icon [icon]="['fas', 'money-bill-alt']"></fa-icon>
      </h3>
      <table class="table notd-backoffice-table small" *ngIf="!loadingSearchResults && searchResults.length > 0">
        <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col" class="text-center">Selling user</th>
          <th scope="col" class="text-center">Purchasing user</th>
          <th scope="col" class="text-center">Stream</th>
          <th scope="col" class="text-center">Post</th>
          <th scope="col" class="text-center">Price</th>
          <th scope="col" class="text-center">Earnings / Commission</th>
          <th scope="col" class="text-center">Date</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of searchResults; let i = index">
            <td>{{i+1}}</td>
            <td class="text-center">
              <fa-icon *ngIf="!result.displaySellerName" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
              <a *ngIf="result.displaySellerName" [routerLink]="['/user', result.sellerId]" class="link" title="{{result.sellerId}}">{{result.displaySellerName}}</a>
            </td>
            <td class="text-center">
              <fa-icon *ngIf="!result.displayBuyerName" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
              <a *ngIf="result.displayBuyerName" [routerLink]="['/user', result.buyerId]" class="link" title="{{result.buyerId}}">{{result.displayBuyerName}}</a>
            </td>
            <td class="text-center">
              <fa-icon *ngIf="!result.streamName" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
              <span *ngIf="result.streamName">{{result.streamName}}</span>
            </td>
            <td class="text-center">post</td>
            <td class="text-center">{{result.price / 100}}</td>
            <td class="text-center">{{result.commission_cc / 100}}</td>
            <td class="text-center">{{result.created | date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loadingSearchResults">
        <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
      </div>
      <div class="text-center" *ngIf="!loadingSearchResults && (searchResults.length === 0 || !searchResults)">
        No data result in this date range.
      </div>
    </div>
  </div>
</div>

