import {Component, Input} from '@angular/core';

@Component({
  selector: 'external-link-icon',
  templateUrl: './external-link-icon.component.html',
  styleUrls: ['./external-link-icon.component.scss'],
})
export class ExternalLinkComponent {
  @Input() link: string;
  @Input() title: string;
  @Input() label: string;
}
