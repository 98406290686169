import * as debug from 'debug';
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
(<any>window).debugApp = debug; // The typescript way to extend window

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class AppComponent {
  constructor() {
    console.log('Notd Backoffice started');
  }
}
