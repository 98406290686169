import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {PayoutsComponent} from './payouts.component';
import {SideMenuModule} from '../../side-menu/side-menu.module';
import {RouterModule} from '@angular/router';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faPen, faSync, faUsers} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: PayoutsComponent }]),
    CommonModule,
    FormsModule,
    SideMenuModule,
    FontAwesomeModule
  ],
  declarations: [
    PayoutsComponent
  ],
  providers: [
  ]
})
export class PayoutsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faUsers, faPen);
  }
}
