import * as Debug from 'debug';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../../app/shared/services/user_service';
const debug = Debug('notd:backoffice:AuthGuard');

@Injectable()
export class AuthGuard  {

  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = this.userService.isLoggedIn();
    debug(`userService.isLoggedIn: ${isLoggedIn}`);

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
      return false;
    }
    return isLoggedIn;
  }
}
