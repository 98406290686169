import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {InputTypeCounterDirective} from './input-type-counter.directive';

@NgModule({
  declarations: [ InputTypeCounterDirective ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ InputTypeCounterDirective ]
})

export class InputTypeCounterModule {}
