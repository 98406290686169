import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FieldEditorComponent} from './field-editor.component';

@NgModule({
  declarations: [ FieldEditorComponent ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ FieldEditorComponent ]
})

export class FieldEditorModule {}
