import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NotesComponent} from './notes.component';
import {NotesRoutingModule} from './notes-routing.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {SideMenuModule} from '../side-menu/side-menu.module';
import {ConfirmationModalModule} from '../../app/shared/confirmation-modal/confirmaton-modal.module';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faExternalLink, faList, faPenSquare, faSync, faTrash} from '@fortawesome/free-solid-svg-icons';
import {faStickyNote} from '@fortawesome/free-regular-svg-icons';
import { ExternalLinkModule } from '../shared/external-link-icon/external-link-icon.module';
import { ColumnSorterModule } from '../shared/column-sorter/column-sorter.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NotesRoutingModule,
    SideMenuModule,
    ConfirmationModalModule,
    NgbPaginationModule,
    FontAwesomeModule,
    ExternalLinkModule,
    ColumnSorterModule
  ],
  declarations: [
    NotesComponent
  ],
  providers: [
  ]
})
export class NotesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faStickyNote, faList, faPenSquare, faTrash, faExternalLink);
  }
}
